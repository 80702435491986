import React from "react"
import imageHintBackgroundImage from '../../../images/games/wordFinder/hintImageBackground.png';
import { DbOrUploadImage, isImageUploadType } from "../../../services/ImageHelper";
import { ImageUploadType } from "../../../model/ImageUploadType";
import { DbImage } from "../../../model/DbImage";
import { arrayToClassName } from "../../../services/CustomFunctions";

interface Props {
    contentToFind: string;
    hintImage? : DbImage | ImageUploadType;
    isRtl?: boolean; 
}

const HintPreview = (props: Props) => {
    const {contentToFind, hintImage, isRtl} = props; 
    if (hintImage) return(
        <ImageHintPreview hintImage={hintImage}/>
    )
    else if (contentToFind.trim().length === 0) return null;
    else return(
        <div className={arrayToClassName(['hint-text', isRtl && 'rtl'])}>{contentToFind}</div>
    );
}
interface ImageHintProps{
    hintImage : DbImage | ImageUploadType;
}
export const ImageHintPreview = (props: ImageHintProps) => {
    const {hintImage} = props; 
    return(
        <div className='hint-image-wrapper'>
            <img src={imageHintBackgroundImage} className="hint-image-background" alt="background"/>
            <DbOrUploadImage 
                className='hint-image' 
                realSize 
                image={hintImage} 
                style={
                    isImageUploadType(hintImage) ? 
                        {
                            width: hintImage.previewWidth ? Math.min(hintImage.width, hintImage.previewWidth ) : undefined, 
                            height: hintImage.previewHeight ? Math.min(hintImage.height, hintImage.previewHeight) : undefined
                        } : 
                        undefined
                }
            />
        </div>
    ); 
}

export default HintPreview; 
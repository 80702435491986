import React, { FormEvent, useContext, useEffect, useState } from 'react'
import Dialog from '../../Dialog';
import { GlobalModalContext, SecureContext } from '../../_MyFloor/MyFloorApp';
import { AccountContext } from '../../../api/AccountContext';
import { GeneralError } from '../../Error/GeneralError';
import { SelectFromPool, SubmitButton } from '../FormGroup';
import Translate from '../../Helper/Translate';
import { courseList, Subject, SubjectArea, subjectAreas } from '../../../model/Subject';
import { isFetchError } from '../../../services/FetchHelper';
import { UserPreferences } from '../../../model/AccountType';

export const UserPreferencesForm = () => {
    const {me} = useContext(SecureContext);
    const [model, setModel] = useState<UserPreferences>(me?.preferences || {subjects: [], subjectAreas: []});
    const [updateTags, loading, error] = AccountContext.useUpdatePreferences();
    useEffect(() => {
        me && setModel(me.preferences || {subjects: [], subjectAreas: []});
    }, [me]);
    const {setShowUserPreferencesDialog} = useContext(GlobalModalContext);

    const onSubmit = async (e: FormEvent) => {
        e.preventDefault();
        if(!me) return; 
        const result = await updateTags(me.id, model);
        if(!isFetchError(result)){
            setShowUserPreferencesDialog(false);
        }
    }
    return(
        <Dialog onClose={() => setShowUserPreferencesDialog(false)} loading={loading} className='user-tags-dialog'>
            <form onSubmit={onSubmit}>
                <div className='user-tags-description'><Translate id={'user_tags_description'}/></div>
                <UserPreferencesFormGroup preferences={model} updatePreferences={setModel}/>
                <GeneralError error={error} />
                <SubmitButton split />
            </form>
        </Dialog>
    )
}

interface Props{
    preferences: UserPreferences;
    updatePreferences: (x: UserPreferences) => void;     
}
const UserPreferencesFormGroup = (props: Props) => {
    const {preferences, updatePreferences: _updatePreferences} = props; 
    const choices: string[] = [...courseList, ...subjectAreas]; 
    const updatePreferences = (tags: string[]) => {
        const subjects = tags.filter(x => courseList.map(course => course.toString()).includes(x)) as Subject[]; 
        const subAreas = tags.filter(x => subjectAreas.map(subjectArea => subjectArea.toString()).includes(x)) as SubjectArea[]; 
        _updatePreferences({subjects, subjectAreas: subAreas})
    }

    return (
        <SelectFromPool 
            pool={choices.map(name => ({name: name, value: name, translate: true}))} 
            name='user_tags_subjects' 
            selected={[...preferences?.subjects, ...preferences?.subjectAreas]} 
            onChange={e => updatePreferences(e.target.value)}
            labelWidth100
            placeholder={'user_tags_subjects_choose'}
            labelName='user_tags_subjects' 
        />
    ); 
}; 

export default UserPreferencesFormGroup;
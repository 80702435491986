import React, { FormEvent, useEffect, useState } from 'react';
import { Ad, AdType, adSizes, adTypes } from '../../model/Advertisement/Ad';
import Dialog from '../Dialog';
import { Button, ImageFormButton, SelectLanguage, SelectList, SubmitButton } from '../forms/FormGroup';
import { AdCreateRequest } from './AdCreateRequest';
import DatePickerFormGroup from '../forms/FormGroups/DatePickerFormGroup';
import moment from 'moment';
import Translate from '../Helper/Translate';
import { AdPreview } from './AdPreview';
import { AdContext } from '../../api/AdContext';
import { GeneralError } from '../Error/GeneralError';
import { isFetchError } from '../../services/FetchHelper';
import { Loading } from '../Loading';
import { OrganizationContext } from '../../api/OrganizationContext';
import { Organization } from '../../model/Organization';
import PoolPicker from '../forms/Organization/PoolPicker';

interface Props{
    model: Ad|null;
    onClose: () => void;
    orgTypes: string[];
    orgCountries: string[];
    onUpdate: (ad: Ad) => void;
}

export const adTypeNames: {[key in AdType]: string} = {
    BeforeGameStart: "ad_game_start",
    AfterGameEnd: "ad_game_end",
    AboveGridPlaylist: "ad_grid_open",
    LockScreen: "ad_lock_screen"
}

const AdForm = (props: Props) => {
    const {model: _model, onClose, onUpdate, orgTypes, orgCountries} = props;
    const [model, setModel] = useState<AdCreateRequest>({
        adType: _model?.adType ?? "",
        countries: _model?.target.countries ?? [],
        types: _model?.target.types ?? [],
        startEpoch: _model?.startEpoch ?? 0,
        image: undefined,
        language: _model?.target.language ?? "en",
        resellerIds: _model?.target.resellerIds ?? [],
        resellerIdBlacklist: _model?.target.resellerIdBlacklist ?? []
    });
    const [reach, setReach] = useState(0);
    const [reachInvalid, setReachInvalid] = useState(true);
    const [resellers, setResellers] = useState<Organization[]>();

    const [createAd, loadingCreate, errorCreate] = AdContext.useCreate();
    const [updateAd, loadingUpdate, errorUpdate] = AdContext.useUpdate();
    const [checkReach, loadingReach] = AdContext.useReach();
    const [getResellers, loadingResellers] = OrganizationContext.useResellers();
    const error = errorCreate || errorUpdate;
 
    useEffect(() => {
        getResellers().then(x => !isFetchError(x) && setResellers(x));
    }, [getResellers]);

    useEffect(() => {
        setReachInvalid(true);
    }, [model])

    const onSubmit = async (e: FormEvent) => {
        e.preventDefault();
        const result = _model?.id ? await updateAd(_model.id, model) : await createAd(model);
        if(!isFetchError(result)){
            onUpdate(result);
            onClose();
        }
    }

    const checkReachClick = async () => {
        if(!model.language){
            setReach(0);
            setReachInvalid(false);
            return;
        }
        const result = await checkReach({
            countries: model.countries,
            types: model.types,
            language: model.language,
            resellerIds: model.resellerIds,
            resellerIdBlacklist: model.resellerIdBlacklist
        });
        if(!isFetchError(result)){
            setReach(result);
            setReachInvalid(false);
        }
    }

    const invalidForm = !model.adType || (!_model?.id && !model.image) || !model.language;

    return (
        <Dialog onClose={onClose} loading={loadingCreate||loadingUpdate||loadingResellers}>
            <h1><Translate id='ad_form_title' /></h1>
            <form onSubmit={onSubmit}>
                <GeneralError error={error} />
                <div className='flex'>
                    <div>
                        {!_model?.id && 
                            <>
                                <SelectList 
                                    name='adType'
                                    labelName='ad_ad_type' 
                                    onChange={x => setModel(old => ({...old, adType: x.target.value as AdType|"", image: undefined}))} 
                                    defaultValue={model.adType} 
                                    options={[{name: "choose", value: ""}, ...adTypes.map(x => ({name: adTypeNames[x], value: x, translate: true}))]} 
                                />
                                {model.adType && 
                                    <ImageFormButton
                                        imageSubmit={image => setModel(old => ({...old, image}))}
                                        value={model.image}
                                        previewWidth={adSizes[model.adType]?.width}
                                        previewHeight={adSizes[model.adType]?.height}
                                        fixedAspect
                                        forceFixedAspect
                                        name='image'
                                    />
                                }
                            </>
                        }
                        <DatePickerFormGroup 
                            name='startEpoch'
                            label='start'
                            value={moment.unix(model.startEpoch)}
                            onChangeUnix={x => setModel(old => ({...old, startEpoch: x.target.value}))}
                        />
                        <SelectLanguage
                            name='language'
                            defaultValue={model.language}
                            onChange={x => setModel(old => ({...old, language: x.target.value}))}
                        />
                        <div className='relative form-width'>
                            <Loading visible={loadingReach} />
                            <p><Translate id='ad_reach' /></p>
                            <p>{reachInvalid ? <Translate id={'ad_reach_invalid'} /> : reach}</p>
                            <div><Button name='ad_calculate_reach' onClick={checkReachClick}  /></div>
                        </div>
                    </div>
                    <AdPreview model={{...model, image: _model?.image ?? model.image}} />
                </div>
                
                <h2><Translate id='ad_restrict_target' /></h2>
                <p><Translate id='ad_restrict_target_explain' /></p>
                <PoolPicker
                    name='ad_org_type_select'
                    pool={orgTypes.map(x => ({id: x}))}
                    nameFunc={x => x.id}
                    value={model.types}
                    onChange={x => setModel(old => ({...old, types: x}))}
                />
                <PoolPicker 
                    name='ad_org_country_select'
                    pool={orgCountries.map(x => ({id: x}))}
                    nameFunc={x => x.id}
                    value={model.countries}
                    onChange={x => setModel(old => ({...old, countries: x}))}
                />
                <p><Translate id='ad_restrict_target_orgs_explain' /></p>
                {!model.resellerIdBlacklist.length &&
                    <PoolPicker
                        name='ad_reseller_select'
                        pool={resellers || []}
                        nameFunc={x => x.systemName}
                        value={model.resellerIds}
                        onChange={x => setModel(old => ({...old, resellerIds: x}))}
                    />                
                }
                {!model.resellerIds.length && 
                    <PoolPicker
                        name='ad_reseller_block_select'
                        pool={resellers || []}
                        nameFunc={x => x.systemName}
                        value={model.resellerIdBlacklist}
                        onChange={x => setModel(old => ({...old, resellerIdBlacklist: x}))}
                    />
                }
                {invalidForm &&
                    <div>
                        <Translate id='ad_required_fields' />
                    </div>
                }
                <SubmitButton split disabled={invalidForm} />
            </form>
        </Dialog>
    )
}

export default AdForm;
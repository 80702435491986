import { useCallback } from "react";
import { AdCreateRequest, AdUpdateRequest } from "../components/Advertisement/AdCreateRequest";
import { Ad, AdTarget } from "../model/Advertisement/Ad";
import { ContextFunc, ContextReturn, useFetchDelete, useFetchGet, useFetchPost, useFetchPut } from "../services/FetchHelper";


interface IAdContext{
    useAll: ContextFunc<Ad[],[]>;
    useCreate: ContextFunc<Ad, [AdCreateRequest]>;
    useRemove: ContextFunc<void, [string]>;
    useUpdate: ContextFunc<Ad, [string, AdUpdateRequest]>;
    useReach: ContextFunc<number, [AdTarget]>;
}

export const AdContext: IAdContext = {
    useAll: function (): ContextReturn<Ad[], []> {
        const [rawInvoke, loading, error] = useFetchGet<Ad[]>();
        const invoke = useCallback(() => rawInvoke('api/ad'), [rawInvoke]);
        return [invoke, loading, error];
    },
    useCreate: function (): ContextReturn<Ad, [AdCreateRequest]> {
        const [rawInvoke, loading, error] = useFetchPost<Ad>();
        const invoke = useCallback((model: AdCreateRequest) => rawInvoke('api/ad', model), [rawInvoke]);
        return [invoke, loading, error];
    },
    useRemove: () => {
        const [rawInvoke, loading, error] = useFetchDelete<void>();
        const invoke = useCallback((id: string) => rawInvoke(`api/ad/${id}`), [rawInvoke]);
        return [invoke, loading, error];
    },
    useUpdate: function (): ContextReturn<Ad, [string, AdUpdateRequest]> {
        const [rawInvoke, loading, error] = useFetchPut<Ad>();
        const invoke = useCallback((id: string, model: AdUpdateRequest) => rawInvoke(`api/ad/${id}`, model), [rawInvoke]);
        return [invoke, loading, error];
    },
    useReach: () => {
        const [rawInvoke, loading, error] = useFetchPost<number>();
        const invoke = useCallback((target: AdTarget) => rawInvoke('api/ad/checkReach', target), [rawInvoke]);
        return [invoke, loading, error];
    }
}
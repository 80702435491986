import React from "react"
import { Category } from "../../../model/Game/WordFinder/Category";
import GridPreview from "./GridPreview";
import HintBoardPreview from "./HintBoardPreview";
import { hasInvalid, WordFinderGame } from "../../../model/Game/WordFinder/WordFinderGame";
import { useIndexTraverser } from "../../../services/CustomFunctions";
import Translate from "../../Helper/Translate";

interface Props {
    game: WordFinderGame | undefined; 
}

const WordFinderGamePreview = (props : Props) => {
    const {game} = props; 
    const categories = game?.categories.filter(x => x.words.length && x.words.every(w => !hasInvalid(w.contentToFind, x.alphabet))) || []; 
    const category = useIndexTraverser(categories, 5000);
    
    if (!categories.length || !category) return <Translate id="word_finder_no_preview"/>; 
    return(
        <div className='word-finder-game-preview'>
            <RoundPreview category={category}/>
        </div>
    );
}

interface RoundProps {
    category: Category; 
}

const RoundPreview = (props: RoundProps) => {
    const {category} = props; 
    return (
        <div className='word-finder-round-preview'>
            <GridPreview category={category}/>
            <HintBoardPreview category={category}/>
        </div>
    )
}

export default WordFinderGamePreview;
import React, { useMemo } from "react"
import gridBackgroundImg from '../../../images/games/wordFinder/gridBackground.png';
import { Category } from "../../../model/Game/WordFinder/Category";
import { arrayToClassName } from "../../../services/CustomFunctions";
import { getAlphabetChars, hasInvalid } from "../../../model/Game/WordFinder/WordFinderGame";
import { AlphabetType, isRtlAlphabet } from "../../../model/AlphabetType";

interface Props {
    category: Category; 
}

const GridPreview = (props : Props) => {
    const {category} = props; 
    const chars = useMemo(() => 
        {
            const words = category.words.map(x => x.contentToFind); 
            const hasInvalidWord = words.find(word => hasInvalid(word, category.alphabet)); 
            const rowCount = category.grid.rowCount; 
            const columnCount = category.grid.columnCount; 
            const chars = words.length && words.length <= category.grid.columnCount && words.every(x => x.length <= category.grid.columnCount) && !hasInvalidWord ? 
                getFullGrid(words || [], category.alphabet, rowCount, columnCount) : 
                getGridWithRandomChars(category.alphabet, rowCount * columnCount); 
            const res = Array<string[]>(rowCount); 
            for (let i = 0; i < rowCount; i++){
                res[i] = chars.slice(columnCount*i, columnCount*i + columnCount); 
            }
            return res; 
        }, 
        [category.alphabet, category.grid.rowCount, category.grid.columnCount, category.words]
    );  

    return(
        <div className={arrayToClassName(['word-finder-grid-preview-wrapper', isRtlAlphabet(category.alphabet) && 'rtl'])}>
            <img src={gridBackgroundImg} className="word-finder-grid-preview-background" alt="background"/>
            <table>
                <tbody>
                    {chars.map((x, i) => 
                        <tr key={i}>
                            {x.map((y, j) => <td key={j}>{y}</td>)}
                        </tr>
                    )} 
                </tbody>
            </table>
        </div>
    );
}

export default GridPreview;

const getGridWithRandomChars = (alphabet: AlphabetType, count: number) => {
    const chars = getAlphabetChars(alphabet, true); 
    const res: string[] = []; 
    for (let i = 0; i < count; i++){
        const r = Math.floor(Math.random() * chars.length); 
        res.push(chars[r]); 
    }
    return res; 
}

type Direction = 'vertical' | 'diagonal' | 'horizontal'; 
const getFullGrid = (words: string[],  alphabet: AlphabetType, rowCount: number, columnCount: number): string[]  => {
    const supportedCharacters = getAlphabetChars(alphabet, words.every(x => !x.includes(" "))); 
    if (!supportedCharacters.length) return []; 
    const isRtl = isRtlAlphabet(alphabet);

    const getGridWithWords = (): (string | undefined)[] => {

        const getNextCharPosition = (currentPosition: number, direction: Direction): number | undefined => {
            switch(direction){
                case "vertical": {
                    if (currentPosition >= columnCount * (rowCount - 1)) return undefined; 
                    return currentPosition + rowCount; 
                }
                case "horizontal": {
                    if (!isRtl && currentPosition % columnCount === columnCount - 1) return undefined; 
                    if (isRtl && currentPosition % columnCount === 0) return undefined; 
                    return isRtl ? currentPosition - 1 : currentPosition + 1; 
                }
                case "diagonal": {
                    if (!isRtl && currentPosition % columnCount === columnCount - 1) return undefined;
                    if (isRtl && currentPosition % columnCount === 0) return undefined; 
                    if (currentPosition >= columnCount * (rowCount - 1)) return undefined; 
                    return isRtl ? currentPosition + columnCount - 1 : currentPosition + columnCount + 1; 
                }
            }
        }

        const addWord = (array: (string|undefined)[],  index: number, direction: Direction, wordChars: string[]) => {
            let currentPositionInGrid = index; 
            array[currentPositionInGrid] = wordChars[0]; 
            for (let i = 1; i < wordChars.length; i++){
                currentPositionInGrid = getNextCharPosition(currentPositionInGrid, direction)!; 
                array[currentPositionInGrid]=wordChars[i]; 
            }
        }

        const tryAddWordsToGrid = (firstWordIndex: number): (string | undefined)[] | undefined=> {
            const array: (string | undefined)[] = Array.apply(null, Array(rowCount*columnCount)).map(x => undefined); 
            const indices = words.map((_, i) => (firstWordIndex + i) %  words.length); 

            const tryAddWordToGrid = (array: (string | undefined)[], word: string) => {

                const tryAddWordToPosition = (array: (string | undefined)[], index: number, word: string | undefined) => {
                    if (!word) return true; 
                    const wordChars = word.split(""); 
                    if (index >= array.length || (array[index] && array[index] !== wordChars[0])) return false; 
                    const triedDirs = new Set<Direction>(); 
                    const dirs: Direction[] = ['vertical', 'diagonal', 'horizontal']; 
                    const tryDirection = (array: (string|undefined)[], index: number, direction: Direction, wordChars: string[]) => {
                        let nextPosition: number | undefined = index; 
                        const canAddChar = (charToAdd: string, existingChar: string|undefined) => {
                            if(!existingChar) return true; 
                            if(existingChar === charToAdd) return true; 
                            return false; 
                        }
                        for (let i = 0; i < wordChars.length; i++){
                            if(nextPosition === undefined || !canAddChar(wordChars[i], array[nextPosition])) return false;
                            nextPosition = getNextCharPosition(nextPosition, direction); 
                        }
                        return true; 
                    }
                    while (triedDirs.size < dirs.length){
                        const dir = dirs.at(Math.floor(Math.random()*3)); 
                        if (!dir) continue; 
                        triedDirs.add(dir); 
                        if (tryDirection(array, index, dir, wordChars)){
                            addWord(array, index, dir, wordChars); 
                            return true; 
                        }
                    }
                    return false; 
                }
                const a = new Set<number>(); 
                while (a.size < array.length){
                    const pos = Math.floor(Math.random()*array.length); 
                    if (!a.has(pos) && tryAddWordToPosition(array, pos, word)){
                        return true;  
                    }
                    a.add(pos)
                }
                return false; 
            }

            for (const i of indices){
                if (!tryAddWordToGrid(array, words[i])) return undefined;      
            }
            return array; 
        }

        let result: (string|undefined)[] | undefined = undefined; 
        try {
            for (let i = 0; i < words.length; i++){
                const grid = tryAddWordsToGrid(i);
                if(grid) {
                    result = grid; 
                    break; 
                }
            }
        }
        finally{
            // if err or if result was never set in the try block (no arrangement of words succeeded)
            // just arrange the words horizontally
            // currently max words is gridSize and max wordlength is gridsize, so it should work
            if(!result){
                result = Array.apply(null, Array(rowCount*columnCount)).map(x => undefined); 
                for (let i = 0; i < words.length; i++){
                    addWord(result, i*columnCount, "horizontal", words[i].split(""))
                } 
            }
           return result;  
        }
    }

    const grid = getGridWithWords(); 
    return grid.map(x => x || supportedCharacters[Math.floor(Math.random()*supportedCharacters.length)]); 
}
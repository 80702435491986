import enUS from "date-fns/esm/locale/en-US";
import ar from "date-fns/locale/ar";
import da from "date-fns/locale/da";
import de from "date-fns/locale/de";
import es from "date-fns/locale/es";
import he from "date-fns/locale/he";
import it from "date-fns/locale/it";
import nb from "date-fns/locale/nb";
import nl from "date-fns/locale/nl";
import sv from "date-fns/locale/sv";
import fr from "date-fns/locale/fr";
import lt from "date-fns/locale/lt";
import cs from "date-fns/locale/cs";
import sk from "date-fns/locale/sk";

const hebrewSort = [
    'he', 'ar', 'en', "en-AU", "en-CA", "en-US", "en-GB", 'ru', 'ro','fr',"fr-CA", 'es', 'pl', 'am', 'nl', 'de', 'it', 'pt', 'ca','yi','da','no','sv','fi','cs','hu','hy','ka','et','lv','lt','hr','bs','sr','be','uk','el','tr','is','lb','ga','sq',
    'sk','sl','bg','az','uz','ky','kk','mk','mn','tg','gd','gl','cy','eo','eu','ko','zh','ja','th','id','vi','lo','my','km','ig','zu','so','ur','sw','af','mg','mt','ms','ps','fa','ne','mr',
    'hi','bn','pa','gu','ta','te','kn','ml','si','yo','fy','sn','ha','haw','fo','kl','cho'
]

const shortcodes = ["af", "sq","am", "ar", "hy", "az", "eu", "be", "bn", "bs", "bg","ca","ceb","zh","zh-CN","zh-TW","co","hr","cs","da","nl","en", "en-AU", "en-CA", "en-US", "en-GB","eo","et","fi","fr","fr-CA","fy","gl","ka","de","el","gu",
    "ht","ha","haw","he","hi","hmn","hu","is","ig", "id","ga","it","ja","jw","kn","kk","km","ko","ku","ky","lo","la","lv","lt","lb","mk","mg","ms","ml","mt","mi","mr","mn", "my","ne",
    "no","ny","ps","fa","pl","pt","pa","ro","ru","sm","gd","sr","st","sn", "sd","si", "sk","sl","so","es", "su", "sw","sv","tl","tg","ta","te","th","tr","uk","ur", "uz","vi","cy","xh",
    "yi", "yo", "zu",'fo','kl','cho'
];

export interface LanguageCode {
    name: string;
    value: string;
}

// Code to generate the translations list., Don't use this directly because it has not the best browser support.
// Use the array generated instead. (const languageTranslations)
// const genereateLanguageList = () => {
//     // const codes = ["af", "sq","am", "ar", "hy", "az", "eu", "be", "bn", "bs", "bg","ca","ceb","zh","zh-CN","zh-TW","co","hr","cs","da","nl","en","eo","et","fi","fr","fy","gl","ka","de","el","gu",
//     //     "ht","ha","haw","he","hi","hmn","hu","is","ig", "id","ga","it","ja","jw","kn","kk","km","ko","ku","ky","lo","la","lv","lt","lb","mk","mg","ms","ml","mt","mi","mr","mn", "my","ne",
//     //     "no","ny","ps","fa","pl","pt","pa","ro","ru","sm","gd","sr","st","sn", "sd","si", "sk","sl","so","es", "su", "sw","sv","tl","tg","ta","te","th","tr","uk","ur", "uz","vi","cy","xh",
//     //     "yi", "yo", "zu", "fo"
//     // ];
//     //@ts-ignore
//     const daLanguageName = new Intl.DisplayNames(['da'], {type: 'language'});
//     //@ts-ignore
//     const iwLanguageName = new Intl.DisplayNames(['iw'], {type: 'language'});
//     //@ts-ignore
//     const enLanguageName = new Intl.DisplayNames(['en'], {type: 'language'});
//     //@ts-ignore
//     const esLanguageName = new Intl.DisplayNames(['es'], {type: 'language'});
//     //@ts-ignore
//     const arLanguageName = new Intl.DisplayNames(['ar'], {type: 'language'});
//     //@ts-ignore
//     const nlLanguageName = new Intl.DisplayNames(['nl'], {type: 'language'});
//     //@ts-ignore
//     const noLanguageName = new Intl.DisplayNames(['no'], {type: 'language'});
//     //@ts-ignore
//     const svLanguageName = new Intl.DisplayNames(['sv'], {type: 'language'});
//     //@ts-ignore
//     const itLanguageName = new Intl.DisplayNames(['it'], {type: 'language'});
//     //@ts-ignore
//     const deLanguageName = new Intl.DisplayNames(['de'], {type: 'language'});
//     //@ts-ignore
//     const frLanguageName = new Intl.DisplayNames(['fr'], {type: 'language'});
//     //@ts-ignore
//     const ltLanguageName = new Intl.DisplayNames(['lt'], {type: 'language'});
//     //@ts-ignore
//     const csLanguageName = new Intl.DisplayNames(['cs'], {type: 'language'});
//     //@ts-ignore
//     const skLanguageName = new Intl.DisplayNames(['sk'], {type: 'language'});

//     const list = [...shortcodes.map(x => ({
//         iso: x, 
//         da: daLanguageName.of(x), 
//         iw: x === "ar" ? arLanguageName.of(x) : iwLanguageName.of(x), 
//         en: enLanguageName.of(x), 
//         es: esLanguageName.of(x), 
//         ar: arLanguageName.of(x), 
//         nl: nlLanguageName.of(x), 
//         no: noLanguageName.of(x), 
//         sv: svLanguageName.of(x),
//         it: itLanguageName.of(x),
//         de: deLanguageName.of(x),
//         fr: frLanguageName.of(x),
//         lt: ltLanguageName.of(x),
//         cs: csLanguageName.of(x),
//         sk: skLanguageName.of(x),
//         })),
//         {
//             iso: "kl", 
//             da: "grønlandsk", 
//             iw: "גרינלנדית", 
//             en: "Greenlandic", 
//             es: "groenlandés", 
//             ar: "جرينلاند", 
//             nl: "Groenlands", 
//             no: "grønlandsk", 
//             sv: "grönländska",
//             it: "Groenlandese",
//             de: "Grönländisch",
//             fr: "groenlandais",
//             lt: "Grenlandijos",
//             cs: "grónský",
//             sk: "grónsky",
//         },
//         {
//             iso: "cho", 
//             da: "Choctaw", 
//             iw: "צ'וקטאו", 
//             en: "Choctaw", 
//             es: "Choctaw", 
//             ar: "الشوكتو", 
//             nl: "Choctaw", 
//             no: "Choctaw", 
//             sv: "Choctaw",
//             it: "Cioccolata",
//             de: "Choctaw",
//             fr: "Choctaw",
//             lt: "Choctaw",
//             cs: "Choctaw",
//             sk: "Choctaw"
//         }
//     ];
//     return JSON.stringify(list, null, 4);
// }
// console.log(genereateLanguageList());


const languageTranslations = [
    {
        "iso": "af",
        "da": "afrikaans",
        "iw": "אפריקאנס",
        "en": "Afrikaans",
        "es": "afrikáans",
        "ar": "الأفريقانية",
        "nl": "Afrikaans",
        "no": "afrikaans",
        "sv": "afrikaans",
        "it": "afrikaans",
        "de": "Afrikaans",
        "fr": "afrikaans",
        "lt": "afrikanų",
        "cs": "afrikánština",
        "sk": "afrikánčina"
    },
    {
        "iso": "sq",
        "da": "albansk",
        "iw": "אלבנית",
        "en": "Albanian",
        "es": "albanés",
        "ar": "الألبانية",
        "nl": "Albanees",
        "no": "albansk",
        "sv": "albanska",
        "it": "albanese",
        "de": "Albanisch",
        "fr": "albanais",
        "lt": "albanų",
        "cs": "albánština",
        "sk": "albánčina"
    },
    {
        "iso": "am",
        "da": "amharisk",
        "iw": "אמהרית",
        "en": "Amharic",
        "es": "amárico",
        "ar": "الأمهرية",
        "nl": "Amhaars",
        "no": "amharisk",
        "sv": "amhariska",
        "it": "amarico",
        "de": "Amharisch",
        "fr": "amharique",
        "lt": "amharų",
        "cs": "amharština",
        "sk": "amharčina"
    },
    {
        "iso": "ar",
        "da": "arabisk",
        "iw": "العربية",
        "en": "Arabic",
        "es": "árabe",
        "ar": "العربية",
        "nl": "Arabisch",
        "no": "arabisk",
        "sv": "arabiska",
        "it": "arabo",
        "de": "Arabisch",
        "fr": "arabe",
        "lt": "arabų",
        "cs": "arabština",
        "sk": "arabčina"
    },
    {
        "iso": "hy",
        "da": "armensk",
        "iw": "ארמנית",
        "en": "Armenian",
        "es": "armenio",
        "ar": "الأرمنية",
        "nl": "Armeens",
        "no": "armensk",
        "sv": "armeniska",
        "it": "armeno",
        "de": "Armenisch",
        "fr": "arménien",
        "lt": "armėnų",
        "cs": "arménština",
        "sk": "arménčina"
    },
    {
        "iso": "az",
        "da": "aserbajdsjansk",
        "iw": "אזרית",
        "en": "Azerbaijani",
        "es": "azerbaiyano",
        "ar": "الأذربيجانية",
        "nl": "Azerbeidzjaans",
        "no": "aserbajdsjansk",
        "sv": "azerbajdzjanska",
        "it": "azerbaigiano",
        "de": "Aserbaidschanisch",
        "fr": "azerbaïdjanais",
        "lt": "azerbaidžaniečių",
        "cs": "ázerbájdžánština",
        "sk": "azerbajdžančina"
    },
    {
        "iso": "eu",
        "da": "baskisk",
        "iw": "בסקית",
        "en": "Basque",
        "es": "euskera",
        "ar": "الباسكية",
        "nl": "Baskisch",
        "no": "baskisk",
        "sv": "baskiska",
        "it": "basco",
        "de": "Baskisch",
        "fr": "basque",
        "lt": "baskų",
        "cs": "baskičtina",
        "sk": "baskičtina"
    },
    {
        "iso": "be",
        "da": "belarusisk",
        "iw": "בלארוסית",
        "en": "Belarusian",
        "es": "bielorruso",
        "ar": "البيلاروسية",
        "nl": "Belarussisch",
        "no": "belarusisk",
        "sv": "belarusiska",
        "it": "bielorusso",
        "de": "Belarussisch",
        "fr": "biélorusse",
        "lt": "baltarusių",
        "cs": "běloruština",
        "sk": "bieloruština"
    },
    {
        "iso": "bn",
        "da": "bengali",
        "iw": "בנגלית",
        "en": "Bangla",
        "es": "bengalí",
        "ar": "البنغالية",
        "nl": "Bengaals",
        "no": "bengali",
        "sv": "bengali",
        "it": "bengalese",
        "de": "Bengalisch",
        "fr": "bengali",
        "lt": "bengalų",
        "cs": "bengálština",
        "sk": "bengálčina"
    },
    {
        "iso": "bs",
        "da": "bosnisk",
        "iw": "בוסנית",
        "en": "Bosnian",
        "es": "bosnio",
        "ar": "البوسنية",
        "nl": "Bosnisch",
        "no": "bosnisk",
        "sv": "bosniska",
        "it": "bosniaco",
        "de": "Bosnisch",
        "fr": "bosniaque",
        "lt": "bosnių",
        "cs": "bosenština",
        "sk": "bosniačtina"
    },
    {
        "iso": "bg",
        "da": "bulgarsk",
        "iw": "בולגרית",
        "en": "Bulgarian",
        "es": "búlgaro",
        "ar": "البلغارية",
        "nl": "Bulgaars",
        "no": "bulgarsk",
        "sv": "bulgariska",
        "it": "bulgaro",
        "de": "Bulgarisch",
        "fr": "bulgare",
        "lt": "bulgarų",
        "cs": "bulharština",
        "sk": "bulharčina"
    },
    {
        "iso": "ca",
        "da": "catalansk",
        "iw": "קטלאנית",
        "en": "Catalan",
        "es": "catalán",
        "ar": "الكتالانية",
        "nl": "Catalaans",
        "no": "katalansk",
        "sv": "katalanska",
        "it": "catalano",
        "de": "Katalanisch",
        "fr": "catalan",
        "lt": "katalonų",
        "cs": "katalánština",
        "sk": "katalánčina"
    },
    {
        "iso": "ceb",
        "da": "cebuano",
        "iw": "סבואנו",
        "en": "Cebuano",
        "es": "cebuano",
        "ar": "السيبيوانية",
        "nl": "Cebuano",
        "no": "cebuano",
        "sv": "cebuano",
        "it": "cebuano",
        "de": "Cebuano",
        "fr": "cebuano",
        "lt": "sebuanų",
        "cs": "cebuánština",
        "sk": "cebuánčina"
    },
    {
        "iso": "zh",
        "da": "kinesisk",
        "iw": "סינית",
        "en": "Chinese",
        "es": "chino",
        "ar": "الصينية",
        "nl": "Chinees",
        "no": "kinesisk",
        "sv": "kinesiska",
        "it": "cinese",
        "de": "Chinesisch",
        "fr": "chinois",
        "lt": "kinų",
        "cs": "čínština",
        "sk": "čínština"
    },
    {
        "iso": "zh-CN",
        "da": "kinesisk (Kina)",
        "iw": "סינית (סין)",
        "en": "Chinese (China)",
        "es": "chino (China)",
        "ar": "الصينية (الصين)",
        "nl": "Chinees (China)",
        "no": "kinesisk (Kina)",
        "sv": "kinesiska (Kina)",
        "it": "cinese (Cina)",
        "de": "Chinesisch (China)",
        "fr": "chinois (Chine)",
        "lt": "kinų (Kinija)",
        "cs": "čínština (Čína)",
        "sk": "čínština (Čína)"
    },
    {
        "iso": "zh-TW",
        "da": "kinesisk (Taiwan)",
        "iw": "סינית (טייוואן)",
        "en": "Chinese (Taiwan)",
        "es": "chino (Taiwán)",
        "ar": "الصينية (تايوان)",
        "nl": "Chinees (Taiwan)",
        "no": "kinesisk (Taiwan)",
        "sv": "kinesiska (Taiwan)",
        "it": "cinese (Taiwan)",
        "de": "Chinesisch (Taiwan)",
        "fr": "chinois (Taïwan)",
        "lt": "kinų (Taivanas)",
        "cs": "čínština (Tchaj-wan)",
        "sk": "čínština (Taiwan)"
    },
    {
        "iso": "co",
        "da": "korsikansk",
        "iw": "קורסיקנית",
        "en": "Corsican",
        "es": "corso",
        "ar": "الكورسيكية",
        "nl": "Corsicaans",
        "no": "korsikansk",
        "sv": "korsikanska",
        "it": "corso",
        "de": "Korsisch",
        "fr": "corse",
        "lt": "korsikiečių",
        "cs": "korsičtina",
        "sk": "korzičtina"
    },
    {
        "iso": "hr",
        "da": "kroatisk",
        "iw": "קרואטית",
        "en": "Croatian",
        "es": "croata",
        "ar": "الكرواتية",
        "nl": "Kroatisch",
        "no": "kroatisk",
        "sv": "kroatiska",
        "it": "croato",
        "de": "Kroatisch",
        "fr": "croate",
        "lt": "kroatų",
        "cs": "chorvatština",
        "sk": "chorvátčina"
    },
    {
        "iso": "cs",
        "da": "tjekkisk",
        "iw": "צ׳כית",
        "en": "Czech",
        "es": "checo",
        "ar": "التشيكية",
        "nl": "Tsjechisch",
        "no": "tsjekkisk",
        "sv": "tjeckiska",
        "it": "ceco",
        "de": "Tschechisch",
        "fr": "tchèque",
        "lt": "čekų",
        "cs": "čeština",
        "sk": "čeština"
    },
    {
        "iso": "da",
        "da": "dansk",
        "iw": "דנית",
        "en": "Danish",
        "es": "danés",
        "ar": "الدانمركية",
        "nl": "Deens",
        "no": "dansk",
        "sv": "danska",
        "it": "danese",
        "de": "Dänisch",
        "fr": "danois",
        "lt": "danų",
        "cs": "dánština",
        "sk": "dánčina"
    },
    {
        "iso": "nl",
        "da": "nederlandsk",
        "iw": "הולנדית",
        "en": "Dutch",
        "es": "neerlandés",
        "ar": "الهولندية",
        "nl": "Nederlands",
        "no": "nederlandsk",
        "sv": "nederländska",
        "it": "olandese",
        "de": "Niederländisch",
        "fr": "néerlandais",
        "lt": "olandų",
        "cs": "nizozemština",
        "sk": "holandčina"
    },
    {
        "iso": "en",
        "da": "engelsk",
        "iw": "אנגלית",
        "en": "English",
        "es": "inglés",
        "ar": "الإنجليزية",
        "nl": "Engels",
        "no": "engelsk",
        "sv": "engelska",
        "it": "inglese",
        "de": "Englisch",
        "fr": "anglais",
        "lt": "anglų",
        "cs": "angličtina",
        "sk": "angličtina"
    },
    {
        "iso": "en-AU",
        "da": "engelsk (australsk)",
        "iw": "אנגלית (אוסטרליה)",
        "en": "English (Australian)",
        "es": "inglés australiano",
        "ar": "الإنجليزية الأسترالية",
        "nl": "Engels (Australië)",
        "no": "engelsk (Australia)",
        "sv": "australisk engelska",
        "it": "inglese australiano",
        "de": "Englisch (Australien)",
        "fr": "anglais australien",
        "lt": "Australijos anglų",
        "cs": "angličtina (Austrálie)",
        "sk": "angličtina (austrálska)"
    },
    {
        "iso": "en-CA",
        "da": "engelsk (canadisk)",
        "iw": "אנגלית (קנדה)",
        "en": "English (Canadian)",
        "es": "inglés canadiense",
        "ar": "الإنجليزية الكندية",
        "nl": "Engels (Canada)",
        "no": "engelsk (Canada)",
        "sv": "kanadensisk engelska",
        "it": "inglese canadese",
        "de": "Englisch (Kanada)",
        "fr": "anglais canadien",
        "lt": "Kanados anglų",
        "cs": "angličtina (Kanada)",
        "sk": "angličtina (kanadská)"
    },
    {
        "iso": "en-US",
        "da": "engelsk (amerikansk)",
        "iw": "אנגלית (ארצות הברית)",
        "en": "English (American)",
        "es": "inglés estadounidense",
        "ar": "الإنجليزية الأمريكية",
        "nl": "Engels (Verenigde Staten)",
        "no": "engelsk (USA)",
        "sv": "amerikansk engelska",
        "it": "inglese americano",
        "de": "Englisch (Vereinigte Staaten)",
        "fr": "anglais américain",
        "lt": "Jungtinių Valstijų anglų",
        "cs": "angličtina (USA)",
        "sk": "angličtina (americká)"
    },
    {
        "iso": "en-GB",
        "da": "engelsk (britisk)",
        "iw": "אנגלית (בריטניה)",
        "en": "English (British)",
        "es": "inglés británico",
        "ar": "الإنجليزية البريطانية",
        "nl": "Engels (Verenigd Koninkrijk)",
        "no": "engelsk (Storbritannia)",
        "sv": "brittisk engelska",
        "it": "inglese britannico",
        "de": "Englisch (Vereinigtes Königreich)",
        "fr": "anglais britannique",
        "lt": "Didžiosios Britanijos anglų",
        "cs": "angličtina (Velká Británie)",
        "sk": "angličtina (britská)"
    },
    {
        "iso": "eo",
        "da": "esperanto",
        "iw": "אספרנטו",
        "en": "Esperanto",
        "es": "esperanto",
        "ar": "الإسبرانتو",
        "nl": "Esperanto",
        "no": "esperanto",
        "sv": "esperanto",
        "it": "esperanto",
        "de": "Esperanto",
        "fr": "espéranto",
        "lt": "esperanto",
        "cs": "esperanto",
        "sk": "esperanto"
    },
    {
        "iso": "et",
        "da": "estisk",
        "iw": "אסטונית",
        "en": "Estonian",
        "es": "estonio",
        "ar": "الإستونية",
        "nl": "Estisch",
        "no": "estisk",
        "sv": "estniska",
        "it": "estone",
        "de": "Estnisch",
        "fr": "estonien",
        "lt": "estų",
        "cs": "estonština",
        "sk": "estónčina"
    },
    {
        "iso": "fi",
        "da": "finsk",
        "iw": "פינית",
        "en": "Finnish",
        "es": "finés",
        "ar": "الفنلندية",
        "nl": "Fins",
        "no": "finsk",
        "sv": "finska",
        "it": "finlandese",
        "de": "Finnisch",
        "fr": "finnois",
        "lt": "suomių",
        "cs": "finština",
        "sk": "fínčina"
    },
    {
        "iso": "fr",
        "da": "fransk",
        "iw": "צרפתית",
        "en": "French",
        "es": "francés",
        "ar": "الفرنسية",
        "nl": "Frans",
        "no": "fransk",
        "sv": "franska",
        "it": "francese",
        "de": "Französisch",
        "fr": "français",
        "lt": "prancūzų",
        "cs": "francouzština",
        "sk": "francúzština"
    },
    {
        "iso": "fr-CA",
        "da": "fransk (canadisk)",
        "iw": "צרפתית (קנדה)",
        "en": "French (Canadian)",
        "es": "francés canadiense",
        "ar": "الفرنسية الكندية",
        "nl": "Frans (Canada)",
        "no": "fransk (Canada)",
        "sv": "kanadensisk franska",
        "it": "francese canadese",
        "de": "Französisch (Kanada)",
        "fr": "français canadien",
        "lt": "Kanados prancūzų",
        "cs": "francouzština (Kanada)",
        "sk": "francúzština (kanadská)"
    },
    {
        "iso": "fy",
        "da": "vestfrisisk",
        "iw": "פריזית מערבית",
        "en": "Western Frisian",
        "es": "frisón occidental",
        "ar": "الفريزيان",
        "nl": "Fries",
        "no": "vestfrisisk",
        "sv": "västfrisiska",
        "it": "frisone occidentale",
        "de": "Westfriesisch",
        "fr": "frison occidental",
        "lt": "vakarų fryzų",
        "cs": "fríština (západní)",
        "sk": "západná frízština"
    },
    {
        "iso": "gl",
        "da": "galicisk",
        "iw": "גליציאנית",
        "en": "Galician",
        "es": "gallego",
        "ar": "الجاليكية",
        "nl": "Galicisch",
        "no": "galisisk",
        "sv": "galiciska",
        "it": "galiziano",
        "de": "Galicisch",
        "fr": "galicien",
        "lt": "galisų",
        "cs": "galicijština",
        "sk": "galícijčina"
    },
    {
        "iso": "ka",
        "da": "georgisk",
        "iw": "גאורגית",
        "en": "Georgian",
        "es": "georgiano",
        "ar": "الجورجية",
        "nl": "Georgisch",
        "no": "georgisk",
        "sv": "georgiska",
        "it": "georgiano",
        "de": "Georgisch",
        "fr": "géorgien",
        "lt": "gruzinų",
        "cs": "gruzínština",
        "sk": "gruzínčina"
    },
    {
        "iso": "de",
        "da": "tysk",
        "iw": "גרמנית",
        "en": "German",
        "es": "alemán",
        "ar": "الألمانية",
        "nl": "Duits",
        "no": "tysk",
        "sv": "tyska",
        "it": "tedesco",
        "de": "Deutsch",
        "fr": "allemand",
        "lt": "vokiečių",
        "cs": "němčina",
        "sk": "nemčina"
    },
    {
        "iso": "el",
        "da": "græsk",
        "iw": "יוונית",
        "en": "Greek",
        "es": "griego",
        "ar": "اليونانية",
        "nl": "Grieks",
        "no": "gresk",
        "sv": "grekiska",
        "it": "greco",
        "de": "Griechisch",
        "fr": "grec",
        "lt": "graikų",
        "cs": "řečtina",
        "sk": "gréčtina"
    },
    {
        "iso": "gu",
        "da": "gujarati",
        "iw": "גוג׳ארטי",
        "en": "Gujarati",
        "es": "guyaratí",
        "ar": "الغوجاراتية",
        "nl": "Gujarati",
        "no": "gujarati",
        "sv": "gujarati",
        "it": "gujarati",
        "de": "Gujarati",
        "fr": "goudjarati",
        "lt": "gudžaratų",
        "cs": "gudžarátština",
        "sk": "gudžarátčina"
    },
    {
        "iso": "ht",
        "da": "haitisk",
        "iw": "קריאולית (האיטי)",
        "en": "Haitian Creole",
        "es": "criollo haitiano",
        "ar": "الكريولية الهايتية",
        "nl": "Haïtiaans Creools",
        "no": "haitisk",
        "sv": "haitiska",
        "it": "creolo haitiano",
        "de": "Haiti-Kreolisch",
        "fr": "créole haïtien",
        "lt": "Haičio",
        "cs": "haitština",
        "sk": "haitská kreolčina"
    },
    {
        "iso": "ha",
        "da": "hausa",
        "iw": "האוסה",
        "en": "Hausa",
        "es": "hausa",
        "ar": "الهوسا",
        "nl": "Hausa",
        "no": "hausa",
        "sv": "hausa",
        "it": "hausa",
        "de": "Haussa",
        "fr": "haoussa",
        "lt": "hausų",
        "cs": "hauština",
        "sk": "hauština"
    },
    {
        "iso": "haw",
        "da": "hawaiiansk",
        "iw": "הוואית",
        "en": "Hawaiian",
        "es": "hawaiano",
        "ar": "لغة هاواي",
        "nl": "Hawaïaans",
        "no": "hawaiisk",
        "sv": "hawaiiska",
        "it": "hawaiano",
        "de": "Hawaiisch",
        "fr": "hawaïen",
        "lt": "havajiečių",
        "cs": "havajština",
        "sk": "havajčina"
    },
    {
        "iso": "he",
        "da": "hebraisk",
        "iw": "עברית",
        "en": "Hebrew",
        "es": "hebreo",
        "ar": "العبرية",
        "nl": "Hebreeuws",
        "no": "hebraisk",
        "sv": "hebreiska",
        "it": "ebraico",
        "de": "Hebräisch",
        "fr": "hébreu",
        "lt": "hebrajų",
        "cs": "hebrejština",
        "sk": "hebrejčina"
    },
    {
        "iso": "hi",
        "da": "hindi",
        "iw": "הינדי",
        "en": "Hindi",
        "es": "hindi",
        "ar": "الهندية",
        "nl": "Hindi",
        "no": "hindi",
        "sv": "hindi",
        "it": "hindi",
        "de": "Hindi",
        "fr": "hindi",
        "lt": "hindi",
        "cs": "hindština",
        "sk": "hindčina"
    },
    {
        "iso": "hmn",
        "da": "hmong",
        "iw": "המונג",
        "en": "Hmong",
        "es": "hmong",
        "ar": "الهمونجية",
        "nl": "Hmong",
        "no": "hmong",
        "sv": "hmongspråk",
        "it": "hmong",
        "de": "Miao",
        "fr": "hmong",
        "lt": "hmong",
        "cs": "hmongština",
        "sk": "hmongčina"
    },
    {
        "iso": "hu",
        "da": "ungarsk",
        "iw": "הונגרית",
        "en": "Hungarian",
        "es": "húngaro",
        "ar": "الهنغارية",
        "nl": "Hongaars",
        "no": "ungarsk",
        "sv": "ungerska",
        "it": "ungherese",
        "de": "Ungarisch",
        "fr": "hongrois",
        "lt": "vengrų",
        "cs": "maďarština",
        "sk": "maďarčina"
    },
    {
        "iso": "is",
        "da": "islandsk",
        "iw": "איסלנדית",
        "en": "Icelandic",
        "es": "islandés",
        "ar": "الأيسلندية",
        "nl": "IJslands",
        "no": "islandsk",
        "sv": "isländska",
        "it": "islandese",
        "de": "Isländisch",
        "fr": "islandais",
        "lt": "islandų",
        "cs": "islandština",
        "sk": "islandčina"
    },
    {
        "iso": "ig",
        "da": "igbo",
        "iw": "איגבו",
        "en": "Igbo",
        "es": "igbo",
        "ar": "الإيجبو",
        "nl": "Igbo",
        "no": "ibo",
        "sv": "igbo",
        "it": "igbo",
        "de": "Igbo",
        "fr": "igbo",
        "lt": "igbų",
        "cs": "igboština",
        "sk": "igboština"
    },
    {
        "iso": "id",
        "da": "indonesisk",
        "iw": "אינדונזית",
        "en": "Indonesian",
        "es": "indonesio",
        "ar": "الإندونيسية",
        "nl": "Indonesisch",
        "no": "indonesisk",
        "sv": "indonesiska",
        "it": "indonesiano",
        "de": "Indonesisch",
        "fr": "indonésien",
        "lt": "indoneziečių",
        "cs": "indonéština",
        "sk": "indonézština"
    },
    {
        "iso": "ga",
        "da": "irsk",
        "iw": "אירית",
        "en": "Irish",
        "es": "irlandés",
        "ar": "الأيرلندية",
        "nl": "Iers",
        "no": "irsk",
        "sv": "iriska",
        "it": "irlandese",
        "de": "Irisch",
        "fr": "irlandais",
        "lt": "airių",
        "cs": "irština",
        "sk": "írčina"
    },
    {
        "iso": "it",
        "da": "italiensk",
        "iw": "איטלקית",
        "en": "Italian",
        "es": "italiano",
        "ar": "الإيطالية",
        "nl": "Italiaans",
        "no": "italiensk",
        "sv": "italienska",
        "it": "italiano",
        "de": "Italienisch",
        "fr": "italien",
        "lt": "italų",
        "cs": "italština",
        "sk": "taliančina"
    },
    {
        "iso": "ja",
        "da": "japansk",
        "iw": "יפנית",
        "en": "Japanese",
        "es": "japonés",
        "ar": "اليابانية",
        "nl": "Japans",
        "no": "japansk",
        "sv": "japanska",
        "it": "giapponese",
        "de": "Japanisch",
        "fr": "japonais",
        "lt": "japonų",
        "cs": "japonština",
        "sk": "japončina"
    },
    {
        "iso": "jw",
        "da": "javanesisk",
        "iw": "יאוואית",
        "en": "Javanese",
        "es": "javanés",
        "ar": "الجاوية",
        "nl": "Javaans",
        "no": "javanesisk",
        "sv": "javanesiska",
        "it": "giavanese",
        "de": "Javanisch",
        "fr": "javanais",
        "lt": "javiečių",
        "cs": "javánština",
        "sk": "jávčina"
    },
    {
        "iso": "kn",
        "da": "kannada",
        "iw": "קנאדה",
        "en": "Kannada",
        "es": "canarés",
        "ar": "الكانادا",
        "nl": "Kannada",
        "no": "kannada",
        "sv": "kannada",
        "it": "kannada",
        "de": "Kannada",
        "fr": "kannada",
        "lt": "kanadų",
        "cs": "kannadština",
        "sk": "kannadčina"
    },
    {
        "iso": "kk",
        "da": "kasakhisk",
        "iw": "קזחית",
        "en": "Kazakh",
        "es": "kazajo",
        "ar": "الكازاخستانية",
        "nl": "Kazachs",
        "no": "kasakhisk",
        "sv": "kazakiska",
        "it": "kazako",
        "de": "Kasachisch",
        "fr": "kazakh",
        "lt": "kazachų",
        "cs": "kazaština",
        "sk": "kazaština"
    },
    {
        "iso": "km",
        "da": "khmer",
        "iw": "חמרית",
        "en": "Khmer",
        "es": "jemer",
        "ar": "الخميرية",
        "nl": "Khmer",
        "no": "khmer",
        "sv": "kambodjanska",
        "it": "khmer",
        "de": "Khmer",
        "fr": "khmer",
        "lt": "khmerų",
        "cs": "khmérština",
        "sk": "khmérčina"
    },
    {
        "iso": "ko",
        "da": "koreansk",
        "iw": "קוריאנית",
        "en": "Korean",
        "es": "coreano",
        "ar": "الكورية",
        "nl": "Koreaans",
        "no": "koreansk",
        "sv": "koreanska",
        "it": "coreano",
        "de": "Koreanisch",
        "fr": "coréen",
        "lt": "korėjiečių",
        "cs": "korejština",
        "sk": "kórejčina"
    },
    {
        "iso": "ku",
        "da": "kurdisk",
        "iw": "כורדית",
        "en": "Kurdish",
        "es": "kurdo",
        "ar": "الكردية",
        "nl": "Koerdisch",
        "no": "kurdisk",
        "sv": "kurdiska",
        "it": "curdo",
        "de": "Kurdisch",
        "fr": "kurde",
        "lt": "kurdų",
        "cs": "kurdština",
        "sk": "kurdčina"
    },
    {
        "iso": "ky",
        "da": "kirgisisk",
        "iw": "קירגיזית",
        "en": "Kyrgyz",
        "es": "kirguís",
        "ar": "القيرغيزية",
        "nl": "Kirgizisch",
        "no": "kirgisisk",
        "sv": "kirgiziska",
        "it": "kirghiso",
        "de": "Kirgisisch",
        "fr": "kirghize",
        "lt": "kirgizų",
        "cs": "kyrgyzština",
        "sk": "kirgizština"
    },
    {
        "iso": "lo",
        "da": "lao",
        "iw": "לאו",
        "en": "Lao",
        "es": "lao",
        "ar": "اللاوية",
        "nl": "Laotiaans",
        "no": "laotisk",
        "sv": "laotiska",
        "it": "lao",
        "de": "Laotisch",
        "fr": "lao",
        "lt": "laosiečių",
        "cs": "laoština",
        "sk": "laoština"
    },
    {
        "iso": "la",
        "da": "latin",
        "iw": "לטינית",
        "en": "Latin",
        "es": "latín",
        "ar": "اللاتينية",
        "nl": "Latijn",
        "no": "latin",
        "sv": "latin",
        "it": "latino",
        "de": "Latein",
        "fr": "latin",
        "lt": "lotynų",
        "cs": "latina",
        "sk": "latinčina"
    },
    {
        "iso": "lv",
        "da": "lettisk",
        "iw": "לטבית",
        "en": "Latvian",
        "es": "letón",
        "ar": "اللاتفية",
        "nl": "Lets",
        "no": "latvisk",
        "sv": "lettiska",
        "it": "lettone",
        "de": "Lettisch",
        "fr": "letton",
        "lt": "latvių",
        "cs": "lotyština",
        "sk": "lotyština"
    },
    {
        "iso": "lt",
        "da": "litauisk",
        "iw": "ליטאית",
        "en": "Lithuanian",
        "es": "lituano",
        "ar": "الليتوانية",
        "nl": "Litouws",
        "no": "litauisk",
        "sv": "litauiska",
        "it": "lituano",
        "de": "Litauisch",
        "fr": "lituanien",
        "lt": "lietuvių",
        "cs": "litevština",
        "sk": "litovčina"
    },
    {
        "iso": "lb",
        "da": "luxembourgsk",
        "iw": "לוקסמבורגית",
        "en": "Luxembourgish",
        "es": "luxemburgués",
        "ar": "اللكسمبورغية",
        "nl": "Luxemburgs",
        "no": "luxemburgsk",
        "sv": "luxemburgiska",
        "it": "lussemburghese",
        "de": "Luxemburgisch",
        "fr": "luxembourgeois",
        "lt": "liuksemburgiečių",
        "cs": "lucemburština",
        "sk": "luxemburčina"
    },
    {
        "iso": "mk",
        "da": "makedonsk",
        "iw": "מקדונית",
        "en": "Macedonian",
        "es": "macedonio",
        "ar": "المقدونية",
        "nl": "Macedonisch",
        "no": "makedonsk",
        "sv": "makedonska",
        "it": "macedone",
        "de": "Mazedonisch",
        "fr": "macédonien",
        "lt": "makedonų",
        "cs": "makedonština",
        "sk": "macedónčina"
    },
    {
        "iso": "mg",
        "da": "malagassisk",
        "iw": "מלגשית",
        "en": "Malagasy",
        "es": "malgache",
        "ar": "الملغاشي",
        "nl": "Malagassisch",
        "no": "gassisk",
        "sv": "malagassiska",
        "it": "malgascio",
        "de": "Malagasy",
        "fr": "malgache",
        "lt": "malagasų",
        "cs": "malgaština",
        "sk": "malgaština"
    },
    {
        "iso": "ms",
        "da": "malajisk",
        "iw": "מלאית",
        "en": "Malay",
        "es": "malayo",
        "ar": "الماليزية",
        "nl": "Maleis",
        "no": "malayisk",
        "sv": "malajiska",
        "it": "malese",
        "de": "Malaiisch",
        "fr": "malais",
        "lt": "malajiečių",
        "cs": "malajština",
        "sk": "malajčina"
    },
    {
        "iso": "ml",
        "da": "malayalam",
        "iw": "מליאלאם",
        "en": "Malayalam",
        "es": "malayálam",
        "ar": "المالايالامية",
        "nl": "Malayalam",
        "no": "malayalam",
        "sv": "malayalam",
        "it": "malayalam",
        "de": "Malayalam",
        "fr": "malayalam",
        "lt": "malajalių",
        "cs": "malajálamština",
        "sk": "malajálamčina"
    },
    {
        "iso": "mt",
        "da": "maltesisk",
        "iw": "מלטית",
        "en": "Maltese",
        "es": "maltés",
        "ar": "المالطية",
        "nl": "Maltees",
        "no": "maltesisk",
        "sv": "maltesiska",
        "it": "maltese",
        "de": "Maltesisch",
        "fr": "maltais",
        "lt": "maltiečių",
        "cs": "maltština",
        "sk": "maltčina"
    },
    {
        "iso": "mi",
        "da": "maori",
        "iw": "מאורית",
        "en": "Māori",
        "es": "maorí",
        "ar": "الماورية",
        "nl": "Maori",
        "no": "maori",
        "sv": "maori",
        "it": "maori",
        "de": "Māori",
        "fr": "maori",
        "lt": "maorių",
        "cs": "maorština",
        "sk": "maorijčina"
    },
    {
        "iso": "mr",
        "da": "marathisk",
        "iw": "מראטהית",
        "en": "Marathi",
        "es": "maratí",
        "ar": "الماراثية",
        "nl": "Marathi",
        "no": "marathi",
        "sv": "marathi",
        "it": "marathi",
        "de": "Marathi",
        "fr": "marathi",
        "lt": "maratų",
        "cs": "maráthština",
        "sk": "maráthčina"
    },
    {
        "iso": "mn",
        "da": "mongolsk",
        "iw": "מונגולית",
        "en": "Mongolian",
        "es": "mongol",
        "ar": "المنغولية",
        "nl": "Mongools",
        "no": "mongolsk",
        "sv": "mongoliska",
        "it": "mongolo",
        "de": "Mongolisch",
        "fr": "mongol",
        "lt": "mongolų",
        "cs": "mongolština",
        "sk": "mongolčina"
    },
    {
        "iso": "my",
        "da": "burmesisk",
        "iw": "בורמזית",
        "en": "Burmese",
        "es": "birmano",
        "ar": "البورمية",
        "nl": "Birmaans",
        "no": "burmesisk",
        "sv": "burmesiska",
        "it": "birmano",
        "de": "Birmanisch",
        "fr": "birman",
        "lt": "birmiečių",
        "cs": "barmština",
        "sk": "barmčina"
    },
    {
        "iso": "ne",
        "da": "nepalesisk",
        "iw": "נפאלית",
        "en": "Nepali",
        "es": "nepalí",
        "ar": "النيبالية",
        "nl": "Nepalees",
        "no": "nepali",
        "sv": "nepalesiska",
        "it": "nepalese",
        "de": "Nepalesisch",
        "fr": "népalais",
        "lt": "nepaliečių",
        "cs": "nepálština",
        "sk": "nepálčina"
    },
    {
        "iso": "no",
        "da": "norsk",
        "iw": "נורווגית",
        "en": "Norwegian",
        "es": "noruego",
        "ar": "النرويجية",
        "nl": "Noors",
        "no": "norsk",
        "sv": "norska",
        "it": "norvegese",
        "de": "Norwegisch",
        "fr": "norvégien",
        "lt": "norvegų",
        "cs": "norština",
        "sk": "nórčina"
    },
    {
        "iso": "ny",
        "da": "nyanja",
        "iw": "ניאנג׳ה",
        "en": "Nyanja",
        "es": "nyanja",
        "ar": "النيانجا",
        "nl": "Nyanja",
        "no": "nyanja",
        "sv": "nyanja",
        "it": "nyanja",
        "de": "Nyanja",
        "fr": "chewa",
        "lt": "nianjų",
        "cs": "ňandžština",
        "sk": "ňandža"
    },
    {
        "iso": "ps",
        "da": "pashto",
        "iw": "פאשטו",
        "en": "Pashto",
        "es": "pastún",
        "ar": "البشتو",
        "nl": "Pasjtoe",
        "no": "pashto",
        "sv": "afghanska",
        "it": "pashto",
        "de": "Paschtu",
        "fr": "pachto",
        "lt": "puštūnų",
        "cs": "paštština",
        "sk": "paštčina"
    },
    {
        "iso": "fa",
        "da": "persisk",
        "iw": "פרסית",
        "en": "Persian",
        "es": "persa",
        "ar": "الفارسية",
        "nl": "Perzisch",
        "no": "persisk",
        "sv": "persiska",
        "it": "persiano",
        "de": "Persisch",
        "fr": "persan",
        "lt": "persų",
        "cs": "perština",
        "sk": "perzština"
    },
    {
        "iso": "pl",
        "da": "polsk",
        "iw": "פולנית",
        "en": "Polish",
        "es": "polaco",
        "ar": "البولندية",
        "nl": "Pools",
        "no": "polsk",
        "sv": "polska",
        "it": "polacco",
        "de": "Polnisch",
        "fr": "polonais",
        "lt": "lenkų",
        "cs": "polština",
        "sk": "poľština"
    },
    {
        "iso": "pt",
        "da": "portugisisk",
        "iw": "פורטוגזית",
        "en": "Portuguese",
        "es": "portugués",
        "ar": "البرتغالية",
        "nl": "Portugees",
        "no": "portugisisk",
        "sv": "portugisiska",
        "it": "portoghese",
        "de": "Portugiesisch",
        "fr": "portugais",
        "lt": "portugalų",
        "cs": "portugalština",
        "sk": "portugalčina"
    },
    {
        "iso": "pa",
        "da": "punjabisk",
        "iw": "פנג׳אבי",
        "en": "Punjabi",
        "es": "punyabí",
        "ar": "البنجابية",
        "nl": "Punjabi",
        "no": "panjabi",
        "sv": "punjabi",
        "it": "punjabi",
        "de": "Punjabi",
        "fr": "pendjabi",
        "lt": "pendžabų",
        "cs": "paňdžábština",
        "sk": "pandžábčina"
    },
    {
        "iso": "ro",
        "da": "rumænsk",
        "iw": "רומנית",
        "en": "Romanian",
        "es": "rumano",
        "ar": "الرومانية",
        "nl": "Roemeens",
        "no": "rumensk",
        "sv": "rumänska",
        "it": "rumeno",
        "de": "Rumänisch",
        "fr": "roumain",
        "lt": "rumunų",
        "cs": "rumunština",
        "sk": "rumunčina"
    },
    {
        "iso": "ru",
        "da": "russisk",
        "iw": "רוסית",
        "en": "Russian",
        "es": "ruso",
        "ar": "الروسية",
        "nl": "Russisch",
        "no": "russisk",
        "sv": "ryska",
        "it": "russo",
        "de": "Russisch",
        "fr": "russe",
        "lt": "rusų",
        "cs": "ruština",
        "sk": "ruština"
    },
    {
        "iso": "sm",
        "da": "samoansk",
        "iw": "סמואית",
        "en": "Samoan",
        "es": "samoano",
        "ar": "الساموائية",
        "nl": "Samoaans",
        "no": "samoansk",
        "sv": "samoanska",
        "it": "samoano",
        "de": "Samoanisch",
        "fr": "samoan",
        "lt": "Samoa",
        "cs": "samojština",
        "sk": "samojčina"
    },
    {
        "iso": "gd",
        "da": "skotsk gælisk",
        "iw": "גאלית סקוטית",
        "en": "Scottish Gaelic",
        "es": "gaélico escocés",
        "ar": "الغيلية الأسكتلندية",
        "nl": "Schots-Gaelisch",
        "no": "skotsk-gælisk",
        "sv": "skotsk gäliska",
        "it": "gaelico scozzese",
        "de": "Gälisch (Schottland)",
        "fr": "gaélique écossais",
        "lt": "škotų (gėlų)",
        "cs": "skotská gaelština",
        "sk": "škótska gaelčina"
    },
    {
        "iso": "sr",
        "da": "serbisk",
        "iw": "סרבית",
        "en": "Serbian",
        "es": "serbio",
        "ar": "الصربية",
        "nl": "Servisch",
        "no": "serbisk",
        "sv": "serbiska",
        "it": "serbo",
        "de": "Serbisch",
        "fr": "serbe",
        "lt": "serbų",
        "cs": "srbština",
        "sk": "srbčina"
    },
    {
        "iso": "st",
        "da": "sydsotho",
        "iw": "סותו דרומית",
        "en": "Southern Sotho",
        "es": "sotho meridional",
        "ar": "السوتو الجنوبية",
        "nl": "Zuid-Sotho",
        "no": "sør-sotho",
        "sv": "sydsotho",
        "it": "sotho del sud",
        "de": "Süd-Sotho",
        "fr": "sotho du Sud",
        "lt": "pietų Soto",
        "cs": "sotština (jižní)",
        "sk": "sothčina (južná)"
    },
    {
        "iso": "sn",
        "da": "shona",
        "iw": "שונה",
        "en": "Shona",
        "es": "shona",
        "ar": "الشونا",
        "nl": "Shona",
        "no": "shona",
        "sv": "shona",
        "it": "shona",
        "de": "Shona",
        "fr": "shona",
        "lt": "šonų",
        "cs": "šonština",
        "sk": "šončina"
    },
    {
        "iso": "sd",
        "da": "sindhi",
        "iw": "סינדהית",
        "en": "Sindhi",
        "es": "sindi",
        "ar": "السندية",
        "nl": "Sindhi",
        "no": "sindhi",
        "sv": "sindhi",
        "it": "sindhi",
        "de": "Sindhi",
        "fr": "sindhi",
        "lt": "sindų",
        "cs": "sindhština",
        "sk": "sindhčina"
    },
    {
        "iso": "si",
        "da": "singalesisk",
        "iw": "סינהלה",
        "en": "Sinhala",
        "es": "cingalés",
        "ar": "السنهالية",
        "nl": "Singalees",
        "no": "singalesisk",
        "sv": "singalesiska",
        "it": "singalese",
        "de": "Singhalesisch",
        "fr": "cingalais",
        "lt": "sinhalų",
        "cs": "sinhálština",
        "sk": "sinhalčina"
    },
    {
        "iso": "sk",
        "da": "slovakisk",
        "iw": "סלובקית",
        "en": "Slovak",
        "es": "eslovaco",
        "ar": "السلوفاكية",
        "nl": "Slowaaks",
        "no": "slovakisk",
        "sv": "slovakiska",
        "it": "slovacco",
        "de": "Slowakisch",
        "fr": "slovaque",
        "lt": "slovakų",
        "cs": "slovenština",
        "sk": "slovenčina"
    },
    {
        "iso": "sl",
        "da": "slovensk",
        "iw": "סלובנית",
        "en": "Slovenian",
        "es": "esloveno",
        "ar": "السلوفانية",
        "nl": "Sloveens",
        "no": "slovensk",
        "sv": "slovenska",
        "it": "sloveno",
        "de": "Slowenisch",
        "fr": "slovène",
        "lt": "slovėnų",
        "cs": "slovinština",
        "sk": "slovinčina"
    },
    {
        "iso": "so",
        "da": "somali",
        "iw": "סומלית",
        "en": "Somali",
        "es": "somalí",
        "ar": "الصومالية",
        "nl": "Somalisch",
        "no": "somali",
        "sv": "somaliska",
        "it": "somalo",
        "de": "Somali",
        "fr": "somali",
        "lt": "somaliečių",
        "cs": "somálština",
        "sk": "somálčina"
    },
    {
        "iso": "es",
        "da": "spansk",
        "iw": "ספרדית",
        "en": "Spanish",
        "es": "español",
        "ar": "الإسبانية",
        "nl": "Spaans",
        "no": "spansk",
        "sv": "spanska",
        "it": "spagnolo",
        "de": "Spanisch",
        "fr": "espagnol",
        "lt": "ispanų",
        "cs": "španělština",
        "sk": "španielčina"
    },
    {
        "iso": "su",
        "da": "sundanesisk",
        "iw": "סונדנזית",
        "en": "Sundanese",
        "es": "sundanés",
        "ar": "السوندانية",
        "nl": "Soendanees",
        "no": "sundanesisk",
        "sv": "sundanesiska",
        "it": "sundanese",
        "de": "Sundanesisch",
        "fr": "soundanais",
        "lt": "sundų",
        "cs": "sundština",
        "sk": "sundčina"
    },
    {
        "iso": "sw",
        "da": "swahili",
        "iw": "סווהילי",
        "en": "Swahili",
        "es": "suajili",
        "ar": "السواحلية",
        "nl": "Swahili",
        "no": "swahili",
        "sv": "swahili",
        "it": "swahili",
        "de": "Suaheli",
        "fr": "swahili",
        "lt": "suahilių",
        "cs": "svahilština",
        "sk": "swahilčina"
    },
    {
        "iso": "sv",
        "da": "svensk",
        "iw": "שוודית",
        "en": "Swedish",
        "es": "sueco",
        "ar": "السويدية",
        "nl": "Zweeds",
        "no": "svensk",
        "sv": "svenska",
        "it": "svedese",
        "de": "Schwedisch",
        "fr": "suédois",
        "lt": "švedų",
        "cs": "švédština",
        "sk": "švédčina"
    },
    {
        "iso": "tl",
        "da": "filippinsk",
        "iw": "פיליפינית",
        "en": "Filipino",
        "es": "filipino",
        "ar": "الفلبينية",
        "nl": "Filipijns",
        "no": "filipino",
        "sv": "filippinska",
        "it": "filippino",
        "de": "Filipino",
        "fr": "filipino",
        "lt": "filipiniečių",
        "cs": "filipínština",
        "sk": "filipínčina"
    },
    {
        "iso": "tg",
        "da": "tadsjikisk",
        "iw": "טג׳יקית",
        "en": "Tajik",
        "es": "tayiko",
        "ar": "الطاجيكية",
        "nl": "Tadzjieks",
        "no": "tadsjikisk",
        "sv": "tadzjikiska",
        "it": "tagico",
        "de": "Tadschikisch",
        "fr": "tadjik",
        "lt": "tadžikų",
        "cs": "tádžičtina",
        "sk": "tadžičtina"
    },
    {
        "iso": "ta",
        "da": "tamil",
        "iw": "טמילית",
        "en": "Tamil",
        "es": "tamil",
        "ar": "التاميلية",
        "nl": "Tamil",
        "no": "tamil",
        "sv": "tamil",
        "it": "tamil",
        "de": "Tamil",
        "fr": "tamoul",
        "lt": "tamilų",
        "cs": "tamilština",
        "sk": "tamilčina"
    },
    {
        "iso": "te",
        "da": "telugu",
        "iw": "טלוגו",
        "en": "Telugu",
        "es": "telugu",
        "ar": "التيلوغوية",
        "nl": "Telugu",
        "no": "telugu",
        "sv": "telugu",
        "it": "telugu",
        "de": "Telugu",
        "fr": "télougou",
        "lt": "telugų",
        "cs": "telugština",
        "sk": "telugčina"
    },
    {
        "iso": "th",
        "da": "thai",
        "iw": "תאית",
        "en": "Thai",
        "es": "tailandés",
        "ar": "التايلاندية",
        "nl": "Thai",
        "no": "thai",
        "sv": "thailändska",
        "it": "thailandese",
        "de": "Thailändisch",
        "fr": "thaï",
        "lt": "tajų",
        "cs": "thajština",
        "sk": "thajčina"
    },
    {
        "iso": "tr",
        "da": "tyrkisk",
        "iw": "טורקית",
        "en": "Turkish",
        "es": "turco",
        "ar": "التركية",
        "nl": "Turks",
        "no": "tyrkisk",
        "sv": "turkiska",
        "it": "turco",
        "de": "Türkisch",
        "fr": "turc",
        "lt": "turkų",
        "cs": "turečtina",
        "sk": "turečtina"
    },
    {
        "iso": "uk",
        "da": "ukrainsk",
        "iw": "אוקראינית",
        "en": "Ukrainian",
        "es": "ucraniano",
        "ar": "الأوكرانية",
        "nl": "Oekraïens",
        "no": "ukrainsk",
        "sv": "ukrainska",
        "it": "ucraino",
        "de": "Ukrainisch",
        "fr": "ukrainien",
        "lt": "ukrainiečių",
        "cs": "ukrajinština",
        "sk": "ukrajinčina"
    },
    {
        "iso": "ur",
        "da": "urdu",
        "iw": "אורדו",
        "en": "Urdu",
        "es": "urdu",
        "ar": "الأوردية",
        "nl": "Urdu",
        "no": "urdu",
        "sv": "urdu",
        "it": "urdu",
        "de": "Urdu",
        "fr": "ourdou",
        "lt": "urdų",
        "cs": "urdština",
        "sk": "urdčina"
    },
    {
        "iso": "uz",
        "da": "usbekisk",
        "iw": "אוזבקית",
        "en": "Uzbek",
        "es": "uzbeko",
        "ar": "الأوزبكية",
        "nl": "Oezbeeks",
        "no": "usbekisk",
        "sv": "uzbekiska",
        "it": "uzbeco",
        "de": "Usbekisch",
        "fr": "ouzbek",
        "lt": "uzbekų",
        "cs": "uzbečtina",
        "sk": "uzbečtina"
    },
    {
        "iso": "vi",
        "da": "vietnamesisk",
        "iw": "וייטנאמית",
        "en": "Vietnamese",
        "es": "vietnamita",
        "ar": "الفيتنامية",
        "nl": "Vietnamees",
        "no": "vietnamesisk",
        "sv": "vietnamesiska",
        "it": "vietnamita",
        "de": "Vietnamesisch",
        "fr": "vietnamien",
        "lt": "vietnamiečių",
        "cs": "vietnamština",
        "sk": "vietnamčina"
    },
    {
        "iso": "cy",
        "da": "walisisk",
        "iw": "וולשית",
        "en": "Welsh",
        "es": "galés",
        "ar": "الويلزية",
        "nl": "Welsh",
        "no": "walisisk",
        "sv": "walesiska",
        "it": "gallese",
        "de": "Walisisch",
        "fr": "gallois",
        "lt": "valų",
        "cs": "velština",
        "sk": "waleština"
    },
    {
        "iso": "xh",
        "da": "xhosa",
        "iw": "קוסה",
        "en": "Xhosa",
        "es": "xhosa",
        "ar": "الخوسا",
        "nl": "Xhosa",
        "no": "xhosa",
        "sv": "xhosa",
        "it": "xhosa",
        "de": "Xhosa",
        "fr": "xhosa",
        "lt": "kosų",
        "cs": "xhoština",
        "sk": "xhoština"
    },
    {
        "iso": "yi",
        "da": "jiddisch",
        "iw": "יידיש",
        "en": "Yiddish",
        "es": "yidis",
        "ar": "اليديشية",
        "nl": "Jiddisch",
        "no": "jiddisk",
        "sv": "jiddisch",
        "it": "yiddish",
        "de": "Jiddisch",
        "fr": "yiddish",
        "lt": "jidiš",
        "cs": "jidiš",
        "sk": "jidiš"
    },
    {
        "iso": "yo",
        "da": "yoruba",
        "iw": "יורובה",
        "en": "Yoruba",
        "es": "yoruba",
        "ar": "اليوروبا",
        "nl": "Yoruba",
        "no": "joruba",
        "sv": "yoruba",
        "it": "yoruba",
        "de": "Yoruba",
        "fr": "yoruba",
        "lt": "jorubų",
        "cs": "jorubština",
        "sk": "jorubčina"
    },
    {
        "iso": "zu",
        "da": "zulu",
        "iw": "זולו",
        "en": "Zulu",
        "es": "zulú",
        "ar": "الزولو",
        "nl": "Zoeloe",
        "no": "zulu",
        "sv": "zulu",
        "it": "zulu",
        "de": "Zulu",
        "fr": "zoulou",
        "lt": "zulų",
        "cs": "zuluština",
        "sk": "zuluština"
    },
    {
        "iso": "fo",
        "da": "færøsk",
        "iw": "פארואזית",
        "en": "Faroese",
        "es": "feroés",
        "ar": "الفاروية",
        "nl": "Faeröers",
        "no": "færøysk",
        "sv": "färöiska",
        "it": "faroese",
        "de": "Färöisch",
        "fr": "féroïen",
        "lt": "farerų",
        "cs": "faerština",
        "sk": "faerčina"
    },
    {
        "iso": "kl",
        "da": "grønlandsk",
        "iw": "גרינלנדית",
        "en": "Greenlandic",
        "es": "groenlandés",
        "ar": "جرينلاند",
        "nl": "Groenlands",
        "no": "grønlandsk",
        "sv": "grönländska",
        "it": "Groenlandese",
        "de": "Grönländisch",
        "fr": "groenlandais",
        "lt": "Grenlandijos",
        "cs": "grónský",
        "sk": "grónsky"
    },
    {
        "iso": "cho",
        "da": "Choctaw",
        "iw": "צ'וקטאו",
        "en": "Choctaw",
        "es": "Choctaw",
        "ar": "الشوكتو",
        "nl": "Choctaw",
        "no": "Choctaw",
        "sv": "Choctaw",
        "it": "Cioccolata",
        "de": "Choctaw",
        "fr": "Choctaw",
        "lt": "Choctaw",
        "cs": "Choctaw",
        "sk": "Choctaw"
    }
];

export const myfloorLocalizations = [
    {name: 'Arabic', value:'ar'},
    {name: 'Danish', value:'da'},
    {name: 'Dutch', value:'nl'},
    {name: 'English', value: 'en'},
    {name: 'French', value:'fr'},
    {name: 'German', value:'de'},
    {name: 'Hebrew', value:'iw'},
    {name: 'Italian', value:'it'},
    {name: 'Norwegian', value: 'no'},
    {name: 'Spanish', value:'es'},
    {name: 'Swedish', value:'sv'},
    {name: 'Lithuanian', value: 'lt'},
    {name: 'Czech', value: 'cs'},
    {name: 'Slovak', value: 'sk'}
];

export const localeFromLangCode = (langCode: string|undefined) => {
    if (langCode === "he") langCode = "iw";
    return myfloorLocalizations.find(x => x.value === langCode)?.value ?? "en";
}

export const getFnsLocale = (locale: string) => {
    switch(locale){
        case 'da': return da;
        case 'iw':
        case 'he': return he;
        case 'en': return enUS;
        case 'es': return es;
        case 'ar': return ar;
        case 'nl': return nl;
        case 'no': return nb;
        case 'sv': return sv;
        case 'it': return it;
        case 'de': return de;
        case 'fr': return fr;
        case 'lt': return lt;
        case 'cs': return cs;
        case 'sk': return sk;
    }
}

export const getLanguageName = (locale: string|null|undefined, language: string) => {
    if(language === "iw")language = "he";
    const o = languageTranslations.find(x => x.iso === language);
    if(!o) return "Unknown language";
    switch(locale){

        case 'da': return o.da;
        case 'iw':
        case 'he': return o.iw;
        case 'en': return o.en;
        case 'es': return o.es;
        case 'ar': return o.ar;
        case 'nl': return o.nl;
        case 'no': return o.no;
        case 'sv': return o.sv;
        case 'it': return o.it;
        case 'de': return o.de;
        case 'fr': return o.fr;
        case 'lt': return o.lt;
        case 'cs': return o.cs;
        case 'sk': return o.sk;
        default:
            return o.en;
    }
}

export const defaultLanguageList: (locale?: string) => LanguageCode[] = (locale?: string) => {
    switch(locale){
        case 'da': return languageTranslations.map((x) => ({name: x.da, value: x.iso})).sort((a,b) => a.name.localeCompare(b.name));
        case 'iw': return languageTranslations.map((x) => ({name: x.iw, value: x.iso})).sort((a,b) => ((hebrewSort.findIndex(x => x === a.value)+1) || Number.MAX_VALUE) - ((hebrewSort.findIndex(x => x === b.value)+1) || Number.MAX_VALUE));
        case 'en': return languageTranslations.map((x) => ({name: x.en, value: x.iso})).sort((a,b) => a.name.localeCompare(b.name));
        case 'es': return languageTranslations.map((x) => ({name: x.es, value: x.iso})).sort((a,b) => a.name.localeCompare(b.name));
        case 'ar': return languageTranslations.map((x) => ({name: x.ar, value: x.iso})).sort((a,b) => a.name.localeCompare(b.name));
        case 'nl': return languageTranslations.map((x) => ({name: x.nl, value: x.iso})).sort((a,b) => a.name.localeCompare(b.name));
        case 'no': return languageTranslations.map((x) => ({name: x.no, value: x.iso})).sort((a,b) => a.name.localeCompare(b.name));
        case 'sv': return languageTranslations.map((x) => ({name: x.sv, value: x.iso})).sort((a,b) => a.name.localeCompare(b.name));
        case 'it': return languageTranslations.map((x) => ({name: x.it, value: x.iso})).sort((a,b) => a.name.localeCompare(b.name));
        case 'de': return languageTranslations.map((x) => ({name: x.de, value: x.iso})).sort((a,b) => a.name.localeCompare(b.name));
        case 'fr': return languageTranslations.map((x) => ({name: x.fr, value: x.iso})).sort((a,b) => a.name.localeCompare(b.name));
        case 'lt': return languageTranslations.map((x) => ({name: x.lt, value: x.iso})).sort((a,b) => a.name.localeCompare(b.name));
        case 'cs': return languageTranslations.map((x) => ({name: x.cs, value: x.iso})).sort((a,b) => a.name.localeCompare(b.name));
        case 'sk': return languageTranslations.map((x) => ({name: x.sk, value: x.iso})).sort((a,b) => a.name.localeCompare(b.name));
        default:
            return languageTranslations.map((x) => ({name: x.en, value: x.iso})).sort((a,b) => a.name.localeCompare(b.name));
    }
}

export default shortcodes;
import { useCallback } from "react";
import { ContextFunc, useFetchPut } from "../../services/FetchHelper";
import { createBaseSettingsFormData } from "../../services/FormDataHelper";
import { createBaseGameContext, GameSettings, IBaseGameContext } from "../BaseGameContext";
import { SplatterSpaceGame } from "../../model/Game/SplatterSpace/SplatterSpaceGame";

interface ISplatterSpaceContext extends IBaseGameContext<SplatterSpaceGame>{
    useUpdateSettings: ContextFunc<SplatterSpaceGame, [string, GameSettings]>;
}

export const SplatterSpaceContext: ISplatterSpaceContext = {
    ...createBaseGameContext('SplatterSpace'),
    useUpdateSettings: () => {
        const [rawInvoke, loading, error] = useFetchPut<SplatterSpaceGame>();
        const invoke = useCallback(
            (gameId: string, settings: GameSettings) => 
                rawInvoke(`api/workshop/splatterspace/${gameId}/settings`, createBaseSettingsFormData(settings))
            , [rawInvoke]
        );
        return [invoke, loading, error];
    }
}
import Dialog from "../../Dialog";
import {Organization} from '../../../model/Organization';
import OrganizationList, { InitialFilters } from "../../Organization/OrganizationList";

interface Props {
    onChoose: (o: Organization) => void;
    closeOnChoose?: boolean;
    onClose: () => void;
    filters?: InitialFilters;
}

const OrganizationPicker = (props: Props) => {
    const {onClose, onChoose, closeOnChoose, filters} = props;


    return(
        <Dialog className='organization-chooser-dialog' onClose={onClose} scrollable>
            <div className='organization-container'>
                <OrganizationList onItemClick={o => {onChoose(o); closeOnChoose && onClose();}} filters={filters}  />
                <div className='clear-fix'/>
            </div>
        </Dialog>
    );
};

export default OrganizationPicker;
import React, { MouseEvent, ReactNode, RefObject, useContext } from "react";
import { FetchError, isFetchError } from "../../services/FetchHelper";
import { GeneralError } from "../Error/GeneralError";
import Translate from "../Helper/Translate";
import { Loading } from "../Loading";
import { SecureContext } from "../_MyFloor/MyFloorApp";
import { Dict } from "../../services/JsDict";
import FieldErrors from "../forms/FieldErrors";

interface Props<T>{
    loading?: boolean;
    items: T[];
    heading: ReactNode;
    subheading?: ReactNode; 
    itemRender: (t: T, i: number) => ReactNode;
    className?: string;
    errors?: FetchError|Dict<string[]>;
    errorKey?: string; 
    containerRef?: RefObject<HTMLDivElement>;
    onMouseMove?: (e: MouseEvent<HTMLDivElement>) => void;
    vertical?: boolean; 
    after?: ReactNode; 
}

const ItemContainer = <T extends unknown>(props: Props<T>) => {
    const {isRtl} = useContext(SecureContext);
    const {loading, items, heading, subheading, itemRender, className, errors, errorKey, onMouseMove, containerRef, vertical, after} = props; 

    return(
        <div className={`item-container${className ? ` ${className}` : ''}${vertical ? ' vertical' : ''}`} dir={isRtl ? 'rtl' : 'ltr'}>
            <div >
                <div className='item-container-heading'>{heading}</div>
                {subheading && <div className='item-container-sub-heading'>{subheading}</div>}
            </div>
            {isFetchError(errors) && !errorKey && <GeneralError error={errors} />}
            {errors && errorKey && <div className='form-group'><FieldErrors errors={errors} fieldname={errorKey}/></div>}
            <div className={`list${vertical ? ' vertical' : ''}`} ref={containerRef} onMouseMove={onMouseMove}>
                <Loading visible={loading} />
                {items && items.map(itemRender)}
                {!errors && (!items || items.length === 0) && <div className='secondary'><Translate id={'empty_list'} /></div>}
                {after}
            </div>
        </div>
    )
};

export default ItemContainer;
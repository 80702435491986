import { distinct } from "../services/CustomFunctions";
import { getLanguageName } from "./LocalizationVariables";

export enum AlphabetType {
    Danish = "Danish",
    Swedish = "Swedish",
    Norwegian = "Norwegian",
    Hebrew = "Hebrew",
    English = "English",
    German = "German",
    Dutch = "Dutch",
    Spanish = "Spanish", 
    Malay = "Malay", 
    Italian = "Italian"
}

const rtlAlphabets = [AlphabetType.Hebrew]; 
export const isRtlAlphabet = (alphabet: AlphabetType) => rtlAlphabets.includes(alphabet); 

export const languageCodeToAlphabet: {[key: string]: AlphabetType} = {
    da: AlphabetType.Danish,
    en: AlphabetType.English,
    no: AlphabetType.Norwegian,
    sv: AlphabetType.Swedish,
    nl: AlphabetType.Dutch,
    he: AlphabetType.Hebrew,
    iw: AlphabetType.Hebrew,
    de: AlphabetType.German,
    es: AlphabetType.Spanish,
    ms: AlphabetType.Malay, 
    it: AlphabetType.Italian
}

export const alphabetTypeToLanguageName = (x: AlphabetType, locale: string) =>  {
    const getLanguageCode = (x: AlphabetType) => 
        Object.entries(languageCodeToAlphabet).find(alphabet => alphabet[1] === x)?.[0]; 
    return getLanguageName(locale, getLanguageCode(x) || 'en'); 
}

export const alphabetTypeToAcceptedLetters: {[key in AlphabetType]: string[]} = {
    Danish: [ "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k","l","m","n","o","p","q","r","s","t","u","v","w","x","z","y","æ","ø","å", " "],
    English: [ "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k","l","m","n","o","p","q","r","s","t","u","v","w","x","z","y", " "],
    Dutch:  [ "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k","l","m","n","o","p","q","r","s","t","u","v","w","x","z","y", " "],
    German:  [ "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k","l","m","n","o","p","q","r","s","t","u","v","w","x","z","y", 'ä', 'ö', 'ü', 'ß', " "],
    Hebrew: [ "ב" ,"ג" ,"ד" ,"ה" ,"ו", "ז", "ח", "ט", "י", "כ", "ל", "מ", "נ", "ס", "ע", "פ", "צ", "ק", "ר", "ש", "ת", "\"", "-", "'", "ף", "ך", "ץ","ן" ,"ם", "א", " "],
    Malay:  [ "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k","l","m","n","o","p","q","r","s","t","u","v","w","x","z","y", " "],
    Norwegian: [ "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k","l","m","n","o","p","q","r","s","t","u","v","w","x","z","y","æ","ø","å", " "],
    Spanish: [ "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k","l","m","n","o","p","q","r","s","t","u","v","w","x","z","y","ñ", " "],
    Swedish: [ "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k","l","m","n","o","p","q","r","s","t","u","v","w","x","z","y","ö","ä","å", " "], 
    Italian: ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k","l","m","n","o","p","q","r","s","t","u","v","w","x","z","y", " "]
}

export const containsAlphabetCharacters = (x: string, alpha: AlphabetType) => {
    const alphabet = alphabetTypeToAcceptedLetters[alpha]; 
    return !!x.split("").filter(x => x.trim().length).find(x => alphabet.includes(x)); 
}

export const hasInvalidChar = (_word: string, allowedChars: string[]): boolean => {
    const word = _word.split("");
    return !!word.find(x => allowedChars.indexOf(x) === -1);
}

export const findInvalidChars = (_word: string, allowedChars: string[]): string[] => {
    const word = _word.split(""); 
    return distinct(word.filter(x => allowedChars.indexOf(x) === -1));
}
import { DbImage } from "./DbImage";
import { PermissionType } from "./Permission/PermissionType";
import {PlayListType} from "./PlayListType";
import {BaseGame} from "./Game/BaseGame";
import { UserGroup } from "./UserGroup";
import { Subject, SubjectArea } from "./Subject";

export function accountHasAnyPermission(account: AccountType|null|undefined, ...permissionsToCheck: PermissionType[]): boolean{
    // noinspection PointlessBooleanExpressionJS
    return !!(
        account &&
        account.permissions &&
        account.permissions.some(p => permissionsToCheck.includes(p))
    );
}
export function isAccountType(arg: AccountType|null|undefined): arg is AccountType {
    return arg !== undefined &&
        arg !== null &&
        arg.id !== undefined &&
        'email' in arg &&
        'acceptedEulaVersion' in arg;
}

export function getFullName(account: AccountType | undefined){
    return account ?
        `${account.firstname?.trim() || ''}${account.lastname ? ` ${account.lastname.trim()}` : ''}` :
        ''
}

export function accountIsAdmin(account?: AccountType|null){
    // noinspection PointlessBooleanExpressionJS
    return !!(
        account &&
        account.permissions &&
        account.permissions.includes("Admin")
    );
}

export function accountIsDynamiClass(account?: AccountType|null){
    return !!(
        account &&
        account.permissions &&
        account.email === "info@dynamiclass.com"
    );
}

export function accountIsFromLicensePrivilegedOrg(account?: AccountType|null){
    const orgs = [
        "NTiMA3wBGcYAWeq6sILP", //Dynamiclass (ar)
        "POInvGsBQrfFqsqCZH7W", //Dynamiclass (he)
        "EyHj1YMBlheRX8GAh8q1"  //Intervideo srl
    ]
    return !!(
        account &&
        account.permissions &&
        orgs.find(x => x === account.organizationId)
    );
}

export function accountIsChildAllowed(account?: AccountType|null){
    return (
        accountIsAdmin(account) ||
        accountHasAnyPermission(account, "ChildOrganizations")
    );
}

export function accountIsAuthor(account?: AccountType|null, item?: PlayListType|BaseGame|null): boolean{
    // noinspection PointlessBooleanExpressionJS
    return !!(
        account &&
        item &&
        item.authorIds &&
        item.authorIds.includes(account.id)
    );
}

export function accountIsOwner(account?: AccountType|null, item?: PlayListType|BaseGame|null): boolean{
    // noinspection PointlessBooleanExpressionJS
    return !!(
        account &&
        item &&
        item.ownerId === account.id
    );
}

export interface AccountType {
    id: string;
    email: string;
    firstname: string;
    lastname: string;
    description: string;
    profileImage: DbImage;
    organizationId: string;
    permissions: null | PermissionType[];
    language: string;
    isPublic: boolean;
    acceptedEulaVersion: string;
    followings: string[];
    PlayListFollowings: string[];
    FavoriteGames: string[];
    favoriteFloorId: string;
    isDeactivated?: boolean; 
    trackingOptions?: TrackingOptions;
    latestLoginAction?: LoginAction;
    preferences?: UserPreferences;
}

export interface AccountAndGroupResponse{
    account: AccountType; 
    updatedUserGroups: UserGroup[]; 
}
export interface TrackingOptions {
    loginHistoryConsent: Consent;
}

export interface Consent {
    isGiven: boolean;
    timeOfChangeEpoch: number;
}

export interface LoginAction {
    id: string;
    accountId: string;
    organizationId: string;
    epoch: number;
    ip: string;
}

export interface UserPreferences {
    subjects: Subject[]; 
    subjectAreas: SubjectArea[]; 
}
import React, { FormEvent, useState } from "react";
import { isFetchError} from "../../../services/FetchHelper";
import { useConfirmDeleteAsync } from "../../forms/games/ConfirmationForm";
import { findInvalid, getAlphabetChars, WordFinderConstants, WordFinderGame } from "../../../model/Game/WordFinder/WordFinderGame";
import { EditWord, Word } from "../../../model/Game/WordFinder/Word";
import { Category } from "../../../model/Game/WordFinder/Category";
import { WordFinderContext } from "../../../api/Game/WordFinderContext";
import Dialog from "../../Dialog";
import Translate from "../../Helper/Translate";
import { GeneralError } from "../../Error/GeneralError";
import InputFormGroup from "../../forms/FormGroups/InputFormGroup";
import { AudioFormButton, ImageFormButton, SubmitButton } from "../../forms/FormGroup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HintBoardPreview from "./HintBoardPreview";
import CategoryPreview from "./CategoryPreview";
import { arrayToClassName, insertOrUpdateItem } from "../../../services/CustomFunctions";
import ItemContainer from "../../ModelPreview/ItemContainer";
import NewGameItemButton from "../NewGameItemButton";
import { ImageHintPreview } from "./HintPreview";

interface Props {
    word?: Word;
    categoryId?: string;
    gameId?: string;
    onChange: (x: WordFinderGame) => void; 
    onClose: () => void;
    categories: Category[]; 
    onCategoryClick: (x: Category | null) => void; 
}

const {maxCategoryCount, maxGridSize, hintImgSize} = WordFinderConstants; 

const WordForm = (props: Props) => {
    const {word, categoryId: originalCategoryId, gameId, onChange, onClose, categories, onCategoryClick} = props;
    const [createWord, loadingCreate, errorCreate] = WordFinderContext.useCreateWord(); 
    const [updateWord, loadingEditWord, errorsEditWord] = WordFinderContext.useUpdateWord(); 
    const [deleteWord, loadingDeleteWord, errorsDeleteWord] = WordFinderContext.useDeleteWord(); 
    const loading = loadingCreate || loadingEditWord || loadingDeleteWord; 
    const error = errorCreate || errorsEditWord || errorsDeleteWord; 

    const [confirmDeleteForm, showConfirmDelete] = useConfirmDeleteAsync(
        gameId && originalCategoryId && word?.id ? async () => deleteWord(gameId, originalCategoryId, word.id) : undefined, 
        x => {onChange(x); onClose();}
    );

    const [model, setModel] = useState<EditWord>({
        ...word, 
        contentToFind: word?.contentToFind || "", 
        categoryId: originalCategoryId
    }); 
    const category = categories.find(x => x.id === model.categoryId);

    const invalidChars = category ? findInvalid(model.contentToFind, category.alphabet) : []; 
    const hasInvalidChars = !!invalidChars.length && !!model.categoryId ; 
    const useImgHintsInCategory = category?.words.filter(x => x.id !== word?.id).every(x => !!x.hintImage); 
    const hasInvalidLength = model.contentToFind.length > (category?.grid.rowCount || maxGridSize); 

    const onSubmit = async (e: FormEvent) => {
        e.preventDefault();
        if (!gameId || !model.categoryId) return; 
        const trimmedModel: EditWord = {...model, contentToFind: model.contentToFind.trim()}; 
        const result = word?.id && originalCategoryId ? await updateWord(gameId, originalCategoryId, word.id, trimmedModel) 
            : await createWord(gameId, model.categoryId, trimmedModel); 
        if (!isFetchError(result)){
            onChange(result); 
            onClose(); 
        }
    };

    return(
        <Dialog 
            onClose={onClose} 
            loading={loading}
            icon={word?.id ? 'trash' : undefined}
            onIconClick={word?.id ? showConfirmDelete : undefined}
            className="word-finder-form"
        >
            {confirmDeleteForm}
            <h2><Translate id="word_finder_create_word_form_title"/></h2>
            <form onSubmit={onSubmit}>
                <div className="top">
                    <div>
                        <GeneralError error={error}/>
                        <InputFormGroup
                            onChange={(e) => setModel(x => ({...x, contentToFind: e.target.value}))} 
                            value={model.contentToFind} 
                            type="text" 
                            labelWidth100 
                            label="word_finder_content_to_find"
                            name="contentToFind"
                            errors={error}
                            tabIndex={1}
                            autoCompleteOff
                        />
                        { hasInvalidLength &&
                            <p dir='auto' className="form-group general-error"><Translate id="word_finder_max_length_exceeded"/></p>
                        }
                        {
                            hasInvalidChars &&
                            <div dir='auto' className="form-group general-error">
                                <p> <Translate id="word_finder_invalid_characters_placeholder" data={{chars: invalidChars.join("")}}/></p>
                                <br/>
                                <p><Translate id="word_finder_valid_characters_placeholder" data={{chars: category ? getAlphabetChars(category.alphabet, false).join("") : ""}}/></p>
                            </div>
                        }
                        <ImageFormButton
                            name={'hintImage'}
                            imageSubmit={(hintImage) => setModel(x => ({...x, hintImage}))}
                            previewWidth={hintImgSize}
                            previewHeight={0}
                            minRatio={0.1}
                            maxRatio={90}
                            value={model.hintImage}
                            fixedAspect={false}
                            errors={error}
                            errorKey={'HintImage'}
                        />
                        <AudioFormButton
                            audioSubmit={(successSound) => setModel(x => ({...x, successSound}))}
                            name={'successSound'}
                            value={model.successSound}
                            errorField="SuccessSound"
                            error={error}
                            showPreview
                        />
                        {model.hintImage && !useImgHintsInCategory && 
                            <div>
                                <ImageHintPreview hintImage={model.hintImage}/>
                                <h3 className='word-finder-form-msg'><Translate id="word_finder_hidden_hint_img"/></h3>
                            </div>
                        }
                    </div>
                    {category && 
                        <div className="right">
                            <h2><Translate id="word_finder_hint_board_preview"/></h2>
                            <HintBoardPreview category={{...category, words: insertOrUpdateItem(category.words, {...model, id: word?.id || ""})}}/>
                        </div>
                    }
                </div>
                <ItemContainer 
                    heading={<h2><Translate id="word_finder_choose_category"/></h2>}
                    items={categories}
                    itemRender={x => {
                        const isDisabled = x.id !== originalCategoryId && x.words.length >= x.grid.rowCount; 
                        return (
                            <div 
                                key={x.id} 
                                className={arrayToClassName(['category-choice', x.id === model.categoryId && 'selected', isDisabled && 'disabled hover-trigger'])}
                            >
                                <div className='edit' onClick={() => onCategoryClick(x)}>
                                    <FontAwesomeIcon icon='edit' /> <Translate id='edit' />
                                </div>
                                <CategoryPreview 
                                    category={x} 
                                    onClick={isDisabled ? undefined : () => setModel(m => ({...m, categoryId: x.id || ""}))}
                                />
                                {isDisabled && <div className='hover-msg'><Translate id='word_finder_max_word_count_pr_category_reached'/></div>}
                            </div>
                        ); 
                    }}
                    className="categories-container"
                    after={
                        <NewGameItemButton 
                            onClick={() => onCategoryClick(null)}
                            onHoverMsg={ categories.length < maxCategoryCount ? "word_finder_category_create" : "word_finder_max_category_count_reached"}
                            disabled={categories.length >= maxCategoryCount}
                        />
                    }
                />
                <SubmitButton split disabled={!gameId || !model.categoryId || hasInvalidChars || hasInvalidLength}/>
            </form>
        </Dialog>
    )
}

export default WordForm;
import { DbSound } from "../../DbSound";
import { SoundUploadType } from "../../SoundUploadType";
import { TextAlignment, TextType } from "../TextType";
import { AlphabetType, languageCodeToAlphabet } from "../../AlphabetType";
import { Grid } from "./Grid";
import { Word } from "./Word";
import { WordFinderConstants } from "./WordFinderGame";

export interface Category{
    id: string; 
    words: Word[]; 
    title: TextType; 
    soundId?: string; 
    sound?: SoundUploadType | DbSound; 
    grid: Grid;
    alphabet: AlphabetType;    
}

export interface EditCategory extends Omit<Category, "id" | "words" | "grid">{
    gridSize: number; 
}

export const editModelToCategory = (model: EditCategory, id?: string, words?: Word[]) => ({
    ...model, 
    id: id || "", 
    words: words || [], 
    grid: {rowCount: model.gridSize, columnCount: model.gridSize}
})
export const categoryToEditModel = (category: Category | null, lang?: string) => 
    category ? {
        ...category, 
        gridSize: category.grid.rowCount,
        alphabet: category.alphabet
    } : 
    getDefaultEmptyCategory(lang); 

export const defaultTitle: TextType = { content: "", fontColorHex: "#F4C243", fontFamily: "Luckiest Guy", fontSize: 15, textAlignment: TextAlignment.Center};
export const getDefaultEmptyCategory = (lang: string | undefined): EditCategory => ({alphabet: languageCodeToAlphabet[lang || 'en'], gridSize: WordFinderConstants.minGridSize, title: defaultTitle}); 

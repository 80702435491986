
import { ContextFunc, useFetchDelete, useFetchPost, useFetchPut } from "../../services/FetchHelper";
import { appendImageDto, appendSoundDto, appendTextDto, createBaseSettingsFormData } from "../../services/FormDataHelper";
import { createBaseGameContext, GameSettings, IBaseGameContext } from "../BaseGameContext";
import { WordFinderGame } from "../../model/Game/WordFinder/WordFinderGame";
import { EditCategory } from "../../model/Game/WordFinder/Category";
import { useCallback } from "react";
import { isDbAudio, isDbImage } from "../../services/ImageHelper";
import { EditWord } from "../../model/Game/WordFinder/Word";


interface IWordFinderContext extends IBaseGameContext<WordFinderGame>{
    useCreateCategory: ContextFunc<WordFinderGame, [string | undefined, EditCategory]>;
    useUpdateCategory: ContextFunc<WordFinderGame, [string, string, EditCategory]>; 
    useDeleteCategory: ContextFunc<WordFinderGame, [string, string]>; 
    useCreateWord: ContextFunc<WordFinderGame, [string, string, EditWord]>; 
    useUpdateWord: ContextFunc<WordFinderGame, [string, string, string, EditWord]>; 
    useDeleteWord: ContextFunc<WordFinderGame, [string, string, string]>; 
    useUpdateSettings: ContextFunc<WordFinderGame, [string, GameSettings]>; 
}

const route = "api/workshop/wordfinder"

export const WordFinderContext: IWordFinderContext = {
    ...createBaseGameContext('WordFinder'), 
    useCreateCategory: () => {
        const [rawInvoke, loading, error] = useFetchPost<WordFinderGame>();
        const invoke = useCallback((gameId: string | undefined, model: EditCategory) => rawInvoke(`${route}/${gameId ? gameId + "/" : ""}category`, createCategoryFormData(model)), [rawInvoke]);
        return [invoke, loading, error];
    }, 
    useUpdateCategory: () => {
        const [rawInvoke, loading, error] = useFetchPut<WordFinderGame>(); 
        const invoke = useCallback((gameId: string, categoryId: string, model: EditCategory) => rawInvoke(`${route}/${gameId}/category/${categoryId}`, editCategoryFormData(model)), [rawInvoke]); 
        return [invoke, loading, error]; 
    }, 
    useDeleteCategory: () => {
        const [rawInvoke, loading, error] = useFetchDelete<WordFinderGame>(); 
        const invoke = useCallback((gameId: string, categoryId: string) => rawInvoke(`${route}/${gameId}/category/${categoryId}/deleteCategoryAndWords`), [rawInvoke]); 
        return [invoke, loading, error]; 
    }, 
    useCreateWord: () => {
        const [rawInvoke, loading, error] = useFetchPost<WordFinderGame>(); 
        const invoke = useCallback((gameId: string, categoryId: string, model: EditWord) => rawInvoke(`${route}/${gameId}/category/${categoryId}/word`, createWordFormData(model)), [rawInvoke]); 
        return [invoke, loading, error]; 
    }, 
    useUpdateWord: () => {
        const [rawInvoke, loading, error] = useFetchPut<WordFinderGame>(); 
        const invoke = useCallback((gameId: string, categoryId: string, wordId: string, model: EditWord) => rawInvoke(`${route}/${gameId}/category/${categoryId}/word/${wordId}`, editWordFormData(model)), [rawInvoke]); 
        return [invoke, loading, error]; 
    }, 
    useDeleteWord: () => {
        const [rawInvoke, loading, error] = useFetchDelete<WordFinderGame>(); 
        const invoke = useCallback((gameId: string, categoryId: string, wordId: string) => rawInvoke(`${route}/${gameId}/category/${categoryId}/word/${wordId}`), [rawInvoke]); 
        return [invoke, loading, error]; 
    }, 
    useUpdateSettings: () => {
        const [rawInvoke, loading, error] = useFetchPut<WordFinderGame>();
        const invoke = useCallback((id: string, model: GameSettings) => rawInvoke(`${route}/${id}/settings`, updateSettingsFormData(model)), [rawInvoke]);
        return [invoke, loading, error];
    },
}

const createCategoryFormData = (model: EditCategory): FormData => {
    let form = new FormData(); 
    form = appendSoundDto(form, model.sound, "Sound"); 
    form = appendTextDto(form, model.title, "Title"); 
    form.append("GridSize", JSON.stringify(model.gridSize)); 
    form.append("Alphabet", model.alphabet); 
    return form; 
}

const editCategoryFormData = (model: EditCategory): FormData => {
    let form = createCategoryFormData(model); 
    form.append("UseCurrentSound", JSON.stringify(!!(model.sound && isDbAudio(model.sound)))); 
    return form; 
}

const createWordFormData = (model: EditWord): FormData => {
    let form = new FormData(); 
    form.append("ContentToFind", model.contentToFind); 
    form = appendSoundDto(form, model.successSound, "SuccessSound"); 
    form = appendImageDto(form, model.hintImage, "HintImage");
    return form; 
}

const editWordFormData = (model: EditWord): FormData => {
    let form = createWordFormData(model); 
    model.categoryId && form.append("CategoryId", model.categoryId); 
    form.append("UseCurrentSuccessSound", JSON.stringify(!!(model.successSound && isDbAudio(model.successSound))));
    form.append("UseCurrentHintImage", JSON.stringify(!!(model.hintImage && isDbImage(model.hintImage)))); 
    return form; 
}

const updateSettingsFormData = (settings: GameSettings): FormData => {
    const formData = createBaseSettingsFormData(settings);
    return formData;
}
import { AlphabetType, alphabetTypeToAcceptedLetters, findInvalidChars, hasInvalidChar } from "../../AlphabetType";

import { BaseGame } from "../BaseGame";
import { Category } from "./Category";

export interface WordFinderGame extends BaseGame {
    categories: Category[];
}

export const WordFinderConstants = {
    minGridSize: 5,
    maxGridSize: 10, 
    maxCategoryCount: 5, 
    hintImgSize: 140
}



export const supportedAlphabets = [
    AlphabetType.English, 
    AlphabetType.Danish, 
    AlphabetType.Norwegian, 
    AlphabetType.Dutch,
    AlphabetType.Malay,
    AlphabetType.German,
    AlphabetType.Spanish,
    AlphabetType.Swedish, 
    AlphabetType.Hebrew, 
    AlphabetType.Italian
]; 

const optionalHebrewChars = ['"', '-', '\'', 'ץ', 'ך', 'ף', 'ן', 'ם']; 

export const getAlphabetChars = (alphabet: AlphabetType, removeWhiteSpace: boolean) => {
    let chars = alphabetTypeToAcceptedLetters[alphabet]; 
    if (removeWhiteSpace){
        chars = chars.filter(x => x !== ' ');
    }
    if (alphabet === AlphabetType.Hebrew){
        chars = chars.filter(x => !optionalHebrewChars.includes(x));
    }
    return chars; 
}

export const hasInvalid = (word: string, alphabet: AlphabetType) => {
    return hasInvalidChar(word, getAlphabetChars(alphabet, false)); // do not filter out white space, it is allowed in words
}

export const findInvalid = (word: string, alphabet: AlphabetType) => {
    return findInvalidChars(word, getAlphabetChars(alphabet, false));
}
export const courseList = [
    'art',
    'biology',
    'business_studies',
    'chemistry',
    'citizenship',
    'culture',
    'geography',
    'geometry',
    'mathematics',
    'physics',
    'history',
    'physical_education',
    'home_economics',
    'music',
    'woodworking',
    'reading',
    'road_safety',
    'life_skills',
    'social_skills',
    'health_nutrition',
    'religion',
    'recess',
    'science_technology',
    'sensory'
] as const;

export const subjectAreas = [
    'early_childhood', 
    'elementary', 
    'middle_school',
    'high_school', 
    'adult_learners',
    'elderly_care',
    'special_education',
    'before_after_care_programs',
    'library',
    'hospital'
] as const; 

export type Subject = typeof courseList[number];
export type SubjectArea = typeof subjectAreas[number];
 
import {Organization, isResellerOrg} from "../../model/Organization";
import {Link} from "react-router-dom";
import moment from 'moment';
import { CategoryTagLike } from './OrgCategoryPicker';
import { OrganizationContext } from "../../api/OrganizationContext";
import SearchResultTable, { TableLocalChange } from "../Collections/SearchResultTable";
import { OrganziationSearchModel, toFilterBool } from "../../model/Request/SearchModel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TableColumn } from "../Collections/SimpleTable";

export interface InitialFilters{
    onlyResellers?: boolean;
    withFloors?: boolean;
    withWalls?: boolean;
}

interface Props{
    onItemClick: (org: Organization) => void;
    showChildCount?: boolean;
    hideLink?: boolean;
    filters?: InitialFilters;
    localChanges?: TableLocalChange<Organization>[];
}

const _pageSize = 50;

const toSortString = (x: string|undefined) => {
    switch(x){
        case "createdDateTime": return "createdEpoch";
        default: return x;
    }
}

export const orgTableColumns = (showChildCount: boolean|undefined, filters: InitialFilters|undefined, hideLink: boolean|undefined): Array<TableColumn<Organization>|undefined> => [
    showChildCount ? {id: "childCount", value: x => x.childOrganizationIds.length, hideHeader: true} : undefined,
    {id: "country", value: x => x.country, filterValue: x => x.country, tdClass: "xs"},
    {id: "state", value: x => x.state, filterValue: x => x.state ?? ""},
    {id: "city", value: x => x.city, filterValue: x => x.city ?? ""},
    {id: "systemName", value: x => x.systemName, filterValue: x => x.systemName, tdClass: "m"},
    {id: "name", value: x => x.name, filterValue: x => x.name, tdClass: "m"},
    {id: "type", value: x => x.type, filterValue: x => x.type},
    {id: "categories", defaultFilter: filters?.onlyResellers ? "salespartner" : undefined, value: x => x.categories.map(c => <CategoryTagLike key={c} cat={c}/>), filterValue: x => x.categories.join(" ")},
    {id: "license_floor_name", defaultFilter: filters?.withFloors, value: x => x.floorCount > 0 && <FontAwesomeIcon icon={'check'} />, filterValue: x => !!x.floorCount, disableSort: true},
    {id: "license_wall_name", defaultFilter: filters?.withWalls, value: x => x.wallCount > 0 && <FontAwesomeIcon icon={'check'} />, filterValue: x => !!x.wallCount, disableSort: true},
    {id: "contactName", value: x => x.contactName, filterValue: x => `${x.contactName} ${x.contactEmail} ${x.contactPhone}`},
    {id: "contactEmail", value: x => x.contactEmail, hideHeader: true, tdClass: "m"},
    {id: "contactPhone", value: x => x.contactPhone, hideHeader: true},
    {id: "createdDateTime", value: x => x.createdEpoch ? moment.unix(x.createdEpoch).format("LL") : "", cmpValue: x => x.createdEpoch},
    {id: "actions", value: x => !hideLink && <Link to={`/discover/org/${x.id}`} onClick={e => e.stopPropagation()}>&gt;</Link>, hideHeader: true, disableSort: true }
]

const OrganizationList = (props: Props) => {
    const {onItemClick, showChildCount, hideLink, filters, localChanges} = props;

    return(
        <SearchResultTable
            contextMethod={OrganizationContext.useSearch}
            pageSize={_pageSize}
            localChanges={localChanges}
            className='org-table'
            defaultSort={{value: "systemName", asc: true}}
            rowClass={x => isResellerOrg(x) ? 'reseller' : ''}
            onRowClick={onItemClick}
            makeSearchModel={(filters, sort, size, offset): OrganziationSearchModel => ({
                size, 
                offset,
                sort: toSortString(sort.value) ?? "systemName",
                ascending: sort.asc,
                filters: {
                    country: filters.find(f => f.id === "country")?.inputValue as string,
                    state: filters.find(f => f.id === "state")?.inputValue as string,
                    city: filters.find(f => f.id === "city")?.inputValue as string,
                    systemName: filters.find(f => f.id === "systemName")?.inputValue as string,
                    name: filters.find(f => f.id === "name")?.inputValue as string,
                    type: filters.find(f => f.id === "type")?.inputValue as string,
                    categories: filters.find(f => f.id === "categories")?.inputValue as string,
                    contact: filters.find(f => f.id === "contactName")?.inputValue as string,
                    hasFloor: toFilterBool(filters.find(f => f.id === "license_floor_name")?.inputValue),
                    hasWall: toFilterBool(filters.find(f => f.id === "license_wall_name")?.inputValue)
                }
            })}
            columns={orgTableColumns(showChildCount, filters, hideLink)}
        />
    )
}

export default OrganizationList;
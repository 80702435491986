import { useCallback } from "react";
import { GardenGame } from "../../model/Game/Garden/GardenGame";
import { ContextFunc, useFetchPut } from "../../services/FetchHelper";
import { createBaseSettingsFormData } from "../../services/FormDataHelper";
import { createBaseGameContext, GameSettings, IBaseGameContext } from "../BaseGameContext";

interface IGardenContext extends IBaseGameContext<GardenGame>{
    useUpdateSettings: ContextFunc<GardenGame, [string, GameSettings]>;
}

export const GardenContext: IGardenContext = {
    ...createBaseGameContext('Garden'),
    useUpdateSettings: () => {
        const [rawInvoke, loading, error] = useFetchPut<GardenGame>();
        const invoke = useCallback(
            (gameId: string, settings: GameSettings) => 
                rawInvoke(`api/workshop/garden/${gameId}/settings`, createBaseSettingsFormData(settings))
            , [rawInvoke]
        );
        return [invoke, loading, error];
    }
}